<script>
import i18n from "@/i18n";
import { CreditChart } from "./chart-options";

// Servies
import AuthService from "@/api/services/auth.service.js";
import CompanyService from "@/api/services/company.service.js";
import NotificationService from "@/api/services/notification.service.js";


// Component/Overlays/Modals
import EditProfileOverlay from "@/shared/components/administrational-components/overlays/edit-user-overlay"
import ChnagePasswordOverlay from "@/shared/components/administrational-components/overlays//change-password-overlay"
import simplebar from "simplebar-vue";
// import NoticeBar from "@/shared/components/notice"

// Enums
import NotificationTypes from "@/shared/enums/notificationTypes.js";

/**
 * Nav-bar Component
 */
export default {
  components: {
    simplebar,
    EditProfileOverlay,
    ChnagePasswordOverlay,
    // NoticeBar
  },
  data() {
    return {
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English"
        },
        {
          flag: require("@/assets/images/flags/germany.jpg"),
          language: "de",
          title: "Deutsch"
        }
      ],
      lan: i18n.locale,
      text: null,
      flag: null,
      loggedInUser: null,
      userEmail: "",
      userFirstName: null,
      userLastName: null,
      userFullName: null,
      userImage: null,
      userCompany: null,
      activatedTab: 0,
      company: null,
      chartDataForCreditBalanceChart: null,
      creditBalanceChartOptions: null,
      unReadNotificationCount: null,
      allUserNotifications: null,
      unReadUserNotifications: [],
      readedUserNotifications: [],
      notificationToDisplay: [],
      /********** Operational Variables *****/
      showCreditNotification: false,
      showManageTeamModal: false,
      showNotifications: false,
      notificationTypes: NotificationTypes,
      isDisplayEditProfileOverlay: false,
      isDisplayChangePasswordOverLay: false
    };
  },
  async created() {
    await this.getLoginUser();
    this.value = this.languages.find(x => x.language === i18n.locale);
    this.text = this.value.title;
    this.flag = this.value.flag;
    try {
      await this.getNotificationsForLoggedinUser();
    } catch (e) {
      console.log("Failed to get notifications.");
    }
  },
  computed: {
    localeFormat() {
      return "de-DE";
    }
  },
  methods: {
    async getNotificationsForLoggedinUser() {
      const allNotifications = await NotificationService.getNotificationsForLoggedinUser();
      if (allNotifications.length > 0) {
        this.allUserNotifications = allNotifications;

        for (const userNotification of this.allUserNotifications) {
          if (!userNotification.readed) {
            this.unReadUserNotifications.push(userNotification);
            this.notificationToDisplay.push(userNotification);
          } else {
            this.readedUserNotifications.push(userNotification);
          }
        }

        this.unReadNotificationCount = this.unReadUserNotifications.length;
      }
      this.showNotifications = true;
    },
    async getLoginUser() {
      let loggedInUserFromFunction = await AuthService.getLoggedInUser();

      if (loggedInUserFromFunction) {
        this.loggedInUser = loggedInUserFromFunction;
        this.userEmail = loggedInUserFromFunction.email;

        if (loggedInUserFromFunction.name.first)
          this.userFirstName = loggedInUserFromFunction.name.first;

        if (loggedInUserFromFunction.name.last)
          this.userLastName = loggedInUserFromFunction.name.last;

        if (this.userFirstName && this.userLastName)
          this.userFullName = this.userFirstName + " " + this.userLastName;

        this.userImage = loggedInUserFromFunction.image;
        this.userCompany = loggedInUserFromFunction.company;

        await this.getCompanyOfLoggedInUser(this.userCompany.id);
      }
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    setLanguage(locale, country, flag) {
      this.lan = locale;
      this.text = country;
      this.flag = flag;
      i18n.locale = locale;
      window.localStorage.setItem("locale", locale);
    },
    reloadPage() {
      window.location.reload();
    },
    async getCompanyOfLoggedInUser(companyId) {
      this.creditBalanceChartOptions = new CreditChart();
      this.company = await CompanyService.getCompanyById(companyId);

      if (this.company.contract && this.company.contract.name !== "Unlimited") {
        this.chartDataForCreditBalanceChart =
          (this.company.wallet.balance / this.company.wallet.received) * 100;

        if (this.chartDataForCreditBalanceChart < 25)
          this.showCreditNotification = true;
      } else {
        this.chartDataForCreditBalanceChart = 100;
      }
    },
    redirectToCreditManagment() {
      this.$router.push("/administration");
    },
    clickManageTeams() {
      this.showManageTeamModal = true;
    },
    async clickReadMoreNotificationButton(notificationId) {
      // change the unReadNotificationCount
      this.unReadNotificationCount -= 1;

      // remove the notification from unread and push to read
      for (let i = 0; i < this.unReadUserNotifications.length; i++) {
        if (this.unReadUserNotifications[i].id === notificationId) {
          this.readedUserNotifications.push(this.unReadUserNotifications[i]);
          this.unReadUserNotifications.splice(i, 1);
        }
      }

      // remove the notification from notificationToDisplay
      for (let i = 0; i < this.notificationToDisplay.length; i++) {
        if (this.notificationToDisplay[i].id === notificationId) {
          this.notificationToDisplay.splice(i, 1);
        }
      }

      await NotificationService.markNotificationAsReaded(notificationId);
    },
    clickUploadErrorNotification(notificationId) {
      this.clickReadMoreNotificationButton(notificationId);
      this.$router.push("/upload-dataset/step1");
    },
    clickUpdateErrorNotification(notificationId, notificationUrl) {
      const datafileId = notificationUrl.split("datafileId=")[1];
      this.clickReadMoreNotificationButton(notificationId);
      this.$router.push(`/datafile-result?datafileId=${datafileId}`);
    },    
    clickUploadSuccessNotification(notificationId, notificationUrl) {
      const datafileId = notificationUrl.split("datafileId=")[1];
      this.clickReadMoreNotificationButton(notificationId);
      this.$router.push(`/datafile-result?datafileId=${datafileId}`);
    },
    clickUpdateSuccessNotification(notificationId, notificationUrl) {
      const datafileId = notificationUrl.split("datafileId=")[1];
      this.clickReadMoreNotificationButton(notificationId);
      this.$router.push(`/datafile-result?datafileId=${datafileId}`);
    },
    clickEditProfile() {
      this.isDisplayEditProfileOverlay = true;
    },
    clickChangePassword() {
      this.isDisplayChangePasswordOverLay = true;
    },
    clickViewTutorial() {
      window.open('https://www.youtube.com/watch?v=P5czyxQfbZw', '_blank');
    }
  }
};
</script>

<template>
  <header id="page-topbar">
    <!-- <NoticeBar /> -->
    <div class="navbar-header">
      <div class="d-flex" v-if="this.loggedInUser && this.company">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link tag="a" to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/Redem Icon.png" alt height="25" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/Redem-light.png" alt height="30" />
            </span>
          </router-link>
        </div>

        <button
          id="vertical-menu-btn"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>

        <div class="dropdown d-none d-lg-inline-block ml-1">
          <div class="mr-3 mx-lg-auto mb-4 float-left float-lg-none">
            <div v-if="userCompany !== null && userCompany.image" class="pt-3">
              <img :src="userCompany.image" style="max-height: 30px" />
            </div>
            <div v-else class="avatar-title bg-white pt-3">
              <img
                src="https://redem-resources.s3.eu-central-1.amazonaws.com/default-images/dafault-company.jpg"
                class="header-profile-user"
                style="width: 40px; height: 40px"
              />
            </div>
          </div>
        </div>
        <div class="dropdown d-none d-lg-inline-block ml-2">
          <div class="pt-4">{{ this.userCompany.name }}</div>
        </div>
      </div>

      <div
        class="d-flex"
        v-if="this.loggedInUser && this.company && this.showNotifications"
      >

        <div class="p-3">
          <b-button variant="outline-light" @click="clickViewTutorial" pill>
            <i class='bx bxs-videos font-size-16 align-middle mr-1 text-blue2'></i>
            {{ $t("menus.navMenu.dropdown.tutorial") }}
          </b-button>
        </div>

        <!-- Translations -->
        <b-dropdown variant="white" right toggle-class="header-item">
          <template v-slot:button-content>
            <img class="mr-1" :src="flag" alt="Header Language" height="16" />
            {{ text }}
          </template>
          <b-dropdown-item
            class="notify-item"
            v-for="(entry, i) in languages"
            :key="`Lang${i}`"
            :value="entry"
            @click="setLanguage(entry.language, entry.title, entry.flag)"
            :class="{ active: lan === entry.language }"
          >
            <img
              :src="`${entry.flag}`"
              alt="user-image"
              class="me-1"
              height="12"
            />
            <span class="align-middle">{{ entry.title }}</span>
          </b-dropdown-item>
        </b-dropdown>

        <!-- Wallet -->
        <b-dropdown
          v-if="this.loggedInUser.accessLevel !== 'SUPER_ADMIN'"
          variant="black"
          toggle-class="header-item noti-icon"
          menu-class="dropdown-menu-lg p-0"
          right
          lazy
        >
          <template v-slot:button-content>
            <i class="bx bx-wallet"></i>
            <span
              v-if="showCreditNotification"
              class="badge bg-danger rounded-pill text-danger"
              style="font-size: 10px"
              >o</span
            >
          </template>

          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">
                  {{ $t("menus.walletMenu.dropdown.title") }}
                </h6>
              </div>
            </div>

            <div v-if="this.loggedInUser.company.contractStatus === 'RUNNING'" >
              <apexchart
                ref="creditChart"
                class="apex-charts apexChartData"
                type="radialBar"
                height="250"
                dir="ltr"
                :series="[Math.round(this.chartDataForCreditBalanceChart)]"
                :options="this.creditBalanceChartOptions.chartOptions"
              > </apexchart>
            </div>

            <div
              class="row align-items-center mt-4"
              v-if="this.loggedInUser.company.contractStatus === 'RUNNING'"
            >
              <div class="col-md-12 text-center">
                <h5
                  class="m-0 mb-1 text-success"
                  v-if="this.company.contract.name === 'Unlimited'"
                >
                  Unlimited
                </h5>
                <h5 class="m-0 mb-1 text-success" v-else>
                  {{
                    this.company.wallet.balance.toLocaleString(localeFormat, {
                      minimumFractionDigits: 0
                    })
                  }}
                </h5>
                {{ $t("menus.walletMenu.dropdown.balance") }}
              </div>
            </div>

            <div
              class="row mt-5"
              v-if="this.loggedInUser.company.contractStatus === 'PAUSED'"
            >
              <div class="col-md-12 text-center">
                <label class="text-danger">
                  {{ $t("menus.walletMenu.dropdown.pausedContract.text1") }}
                </label>
                <br />
                {{ $t("menus.walletMenu.dropdown.pausedContract.text2") }}
              </div>
            </div>

            <div
              class="row mt-4"
              v-if="this.loggedInUser.accessLevel !== 'EMPLOYEE'"
            >
              <div class="col-md-12">
                <b-button
                  class="btn"
                  variant="light"
                  @click="redirectToCreditManagment()"
                  style="width: 100%"
                >
                  <i class="bx bx-money mr-1"></i>
                  {{ $t("menus.walletMenu.dropdown.button.creditManagement") }}
                </b-button>
              </div>
            </div>
          </div>
        </b-dropdown>

        <!-- Notification -->
        <b-dropdown
          variant="black"
          toggle-class="header-item noti-icon"
          menu-class="dropdown-menu-lg p-0"
          right
          lazy
        >
          <template v-slot:button-content>
            <i
              class="bx bx-bell"
              :class="unReadNotificationCount > 0 ? 'bx-tada' : ''"
            ></i>
            <span
              v-if="unReadNotificationCount > 0"
              class="badge bg-blue2 rounded-pill text-white"
              style="font-size: 10px"
              >{{ unReadNotificationCount }}</span
            >
          </template>

          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">{{ $t("general.notification.title") }}</h6>
              </div>
            </div>
          </div>

          <div class="p-3">
            <div class="row">
              <div class="col-md-12">
                <simplebar style="max-height: 300px; overflow-x: hidden">
                  <div
                    class="mb-3"
                    v-for="(notification, notificationIndex) in this
                      .notificationToDisplay"
                    :key="notificationIndex"
                  >
                    <div v-if="notification.type === notificationTypes.UPLOAD_FINISHED">
                      <h6>
                        {{ notification.title + " " + $t("general.notification.datafileUploadSuccessTitle") }}
                      </h6>

                      <p>
                        {{ $t("general.notification.datafileUploadSuccessText") }}
                      </p>

                      <div class="row mt-3">
                        <div class="col-md-6">
                          <span
                            class="text-blue2 font-weight-bold"
                            style="cursor: pointer;"
                            @click="
                              clickUploadSuccessNotification(notification.id, notification.url)
                            "
                          >
                          {{ $t("general.notification.datafileUploadSuccessLink") }}
                          </span>
                        </div>
                        <div class="col-md-6 text-right">
                          <small>
                            {{
                              new Date(
                                notification.createdDate
                              ).toLocaleDateString("de-DE")
                            }}
                          </small>
                        </div>
                      </div>

                      <hr />
                    </div>
                    <div v-else-if="notification.type === notificationTypes.UPDATE_FINISHED">
                      <h6>
                        {{ notification.title + " " + $t("general.notification.datafileUpdateSuccessTitle") }}
                      </h6>

                      <p>
                        {{ $t("general.notification.datafileUpdateSuccessText") }}
                      </p>

                      <div class="row mt-3">
                        <div class="col-md-6">
                          <span
                            class="text-blue2 font-weight-bold"
                            style="cursor: pointer;"
                            @click="
                              clickUpdateSuccessNotification(notification.id, notification.url)
                            "
                          >
                          {{ $t("general.notification.datafileUpdateSuccessLink") }}
                          </span>
                        </div>
                        <div class="col-md-6 text-right">
                          <small>
                            {{
                              new Date(
                                notification.createdDate
                              ).toLocaleDateString("de-DE")
                            }}
                          </small>
                        </div>
                      </div>

                      <hr />
                    </div>                    
                    <div v-else-if="notification.type === notificationTypes.UPLOAD_ERROR">
                      <h6>
                        {{ notification.title + " " + $t("general.notification.datafileUploadErrorTitle") }}
                      </h6>
                      <p>
                        {{ $t("general.notification.datafileUploadErrorText") }}
                      </p>
                      <div class="row mt-3">
                        <div class="col-md-6">
                          <span
                            class="text-blue2 font-weight-bold"
                            style="cursor: pointer;"
                            @click="
                              clickUploadErrorNotification(notification.id)
                            "
                          >
                          {{ $t("general.notification.datafileUploadErrorLink") }}
                          </span>
                        </div>
                        <div class="col-md-6 text-right">
                          <small>
                            {{
                              new Date(
                                notification.createdDate
                              ).toLocaleDateString("de-DE")
                            }}
                          </small>
                        </div>
                      </div>
                      <hr />
                    </div>
                    <div v-else-if="notification.type === notificationTypes.UPDATE_ERROR">
                      <h6>
                        {{ notification.title + " " + $t("general.notification.datafileUpdateErrorTitle") }}
                      </h6>
                      <p>
                        {{ $t("general.notification.datafileUpdateErrorText") }}
                      </p>
                      <div class="row mt-3">
                        <div class="col-md-6">
                          <span
                            class="text-blue2 font-weight-bold"
                            style="cursor: pointer;"
                            @click="
                              clickUpdateErrorNotification(notification.id, notification.url)
                            "
                          >
                          {{ $t("general.notification.datafileUpdateErrorLink") }}
                          </span>
                        </div>
                        <div class="col-md-6 text-right">
                          <small>
                            {{
                              new Date(
                                notification.createdDate
                              ).toLocaleDateString("de-DE")
                            }}
                          </small>
                        </div>
                      </div>
                      <hr />
                    </div>                    
                    <div v-else>
                      <h6>
                        {{ notification.title }}
                      </h6>

                      <p>
                        {{ notification.description }}
                      </p>

                      <div class="row mt-3">
                        <div class="col-md-6">
                          <a
                            :href="notification.url"
                            target="_blank"
                            class="text-blue2 font-weight-bold"
                            @click="
                              clickReadMoreNotificationButton(notification.id)
                            "
                          >
                            {{ $t("general.notification.redeaMore") }}</a
                          >
                        </div>
                        <div class="col-md-6 text-right">
                          <small>
                            {{
                              new Date(
                                notification.createdDate
                              ).toLocaleDateString("de-DE")
                            }}
                          </small>
                        </div>
                      </div>

                      <hr />
                    </div>
                  </div>
                </simplebar>
              </div>
            </div>
          </div>

          <!-- No Notifications -->
          <div class="p-3" v-if="this.notificationToDisplay.length === 0">
            <div class="row mb-4">
              <div class="col-12 text-center mb-2">
                <i class="bx bx-bell-off display-4 text-mid"></i>
              </div>
              <div class="col-12 text-center">
                {{ $t("general.notification.noNewNotification") }}
              </div>
            </div>
          </div>
        </b-dropdown>

        <!-- Full Screen -->
        <div class="dropdown d-none d-lg-inline-block ml-1">
          <button
            type="button"
            class="btn header-item noti-icon"
            @click="initFullScreen"
          >
            <i class="bx bx-fullscreen"></i>
          </button>
        </div>

        <!-- resources -->
        <b-dropdown variant="black" toggle-class="header-item noti-icon" right>
          <template v-slot:button-content>
            <i class="bx bx-info-circle"></i>
          </template>
          <a
            tag="span"
            href="https://redem-resources.s3.eu-central-1.amazonaws.com/two-pager/Redem%C2%AE+Data+Quality+Assurance+-+Kundeninformationen.pptx"
            target="_blank"
            class="text-dark dropdown-item"
          >
            <i class="bx bx-diamond ont-size-16 align-middle mr-1"></i>
            {{ $t("menus.navMenu.dropdown.onePager") }}
          </a>

          <a
            tag="span"
            href="https://help.redem.io"
            target="_blank"
            class="text-dark dropdown-item"
          >
            <i class="bx bx-bulb ont-size-16 align-middle mr-1"></i>
            {{ $t("menus.navMenu.dropdown.helpCenter") }}
          </a>
        </b-dropdown>

        <!-- User -->
        <b-dropdown right variant="black" toggle-class="header-item">
          <template
            v-slot:button-content
            v-slot:content="{ userEmail, userFirstName }"
          >
            <img
              v-if="userImage"
              :src="userImage"
              class="rounded-circle header-profile-user"
            />
            <img
              v-else
              src="https://redem-resources.s3.eu-central-1.amazonaws.com/default-images/default-avatar.png"
              class="rounded-circle header-profile-user"
            />
            <span class="d-none d-xl-inline-block ml-1"
              >{{ userFirstName }}
            </span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <!-- item-->
          <b-dropdown-item @click="clickEditProfile">
            <i class="bx bx-edit font-size-16 align-middle mr-1"></i>
            {{ $t("menus.navMenu.dropdown.editProfile") }}
          </b-dropdown-item>

          <b-dropdown-item @click="clickChangePassword">
            <i class="bx bx-key font-size-16 align-middle mr-1"></i>
            {{ $t("menus.navMenu.dropdown.changePassword") }}
          </b-dropdown-item>

          <a class="dropdown-item"
            href="mailto:support@redem.io?subject=Bug Report"
          >
            <i class="bx bx-bug ont-size-16 align-middle mr-1"></i>
            {{ $t("menus.navMenu.dropdown.bugReport") }}
          </a>
          <b-dropdown-divider></b-dropdown-divider>
          <a href="/logout" class="dropdown-item text-danger">
            <i
              class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"
            ></i>
            {{ $t("menus.navMenu.dropdown.logout") }}
          </a>
        </b-dropdown>
      </div>
    </div>

    <!-- Edit Profile -->
    <EditProfileOverlay
      v-if="isDisplayEditProfileOverlay === true"
      :visible="isDisplayEditProfileOverlay"
      @close="isDisplayEditProfileOverlay = false"
      :user="this.loggedInUser"
      :isEditProfile="true"
      @reload="reloadPage"
    />

    <!-- Chnage Password -->
    <ChnagePasswordOverlay
      v-if="isDisplayChangePasswordOverLay === true"
      :visible="isDisplayChangePasswordOverLay"
      @close="isDisplayChangePasswordOverLay = false"
      :user="this.loggedInUser"
    />

  </header>
</template>
