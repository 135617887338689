var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isComponentLoading
      ? _c("div", { staticClass: "row pt-5" }, [
          _c(
            "div",
            { staticClass: "col-md-12 text-center p-5" },
            [
              _c("b-spinner", {
                staticClass: "m-2",
                staticStyle: { width: "5rem", height: "5rem" },
                attrs: { variant: "mid", role: "status" },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    !_vm.isComponentLoading && _vm.organizations && _vm.organizations.length > 0
      ? _c("div", { staticClass: "mt-4" }, [
          _c("div", { staticClass: "row mb-4" }, [
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c("b-form-input", {
                  attrs: { type: "search", placeholder: "Search" },
                  model: {
                    value: _vm.filter,
                    callback: function ($$v) {
                      _vm.filter = $$v
                    },
                    expression: "filter",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "table-responsive mb-0" },
            [
              _c("b-table", {
                attrs: {
                  items: _vm.dataForTable,
                  fields: _vm.fieldsForTable,
                  responsive: "sm",
                  hover: "",
                  "per-page": _vm.perPage,
                  "current-page": _vm.currentPage,
                  bordered: true,
                  filter: _vm.filter,
                  "filter-included-fields": ["organization"],
                },
                on: {
                  "row-clicked": function ($event) {
                    return _vm.clickOrganizationinfo($event)
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell(organization)",
                      fn: function (data) {
                        return [
                          _c("div", { staticClass: "row align-items-center" }, [
                            _c("div", { staticClass: "col-md-12" }, [
                              _c("img", {
                                staticClass: "rounded-circle",
                                staticStyle: { height: "30px" },
                                attrs: { src: data.value.image },
                              }),
                              _c(
                                "span",
                                { staticStyle: { "font-weight": "600" } },
                                [_vm._v(" " + _vm._s(data.value.name) + " ")]
                              ),
                            ]),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "cell(owner)",
                      fn: function (data) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "row align-items-center justify-content-between",
                            },
                            [
                              _c("div", { staticClass: "col-2" }, [
                                _c("img", {
                                  staticClass: "rounded-circle",
                                  staticStyle: { height: "30px" },
                                  attrs: { src: data.value.image },
                                }),
                              ]),
                              _c("div", { staticClass: "col-10" }, [
                                _c(
                                  "div",
                                  { staticStyle: { "font-weight": "600" } },
                                  [_vm._v(" " + _vm._s(data.value.name) + " ")]
                                ),
                                _c("small", [
                                  _vm._v(" " + _vm._s(data.value.email) + " "),
                                ]),
                              ]),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(startDate)",
                      fn: function (data) {
                        return [
                          _vm._v(
                            " " + _vm._s(_vm.formatDate(data.value)) + " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(remainingDays)",
                      fn: function (data) {
                        return [
                          data.value < 0
                            ? _c("span", [_vm._v(" - ")])
                            : _c("span", [
                                _vm._v(" " + _vm._s(data.value) + " "),
                              ]),
                        ]
                      },
                    },
                    {
                      key: "cell(contractStatus)",
                      fn: function (data) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass:
                                "badge badge-soft-secondary font-size-12",
                              class: {
                                "badge-soft-success":
                                  `${data.value}` === "RUNNING",
                                "badge-soft-danger":
                                  `${data.value}` === "PAUSED",
                              },
                            },
                            [_vm._v(" " + _vm._s(data.value) + " ")]
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(organizationStatus)",
                      fn: function (data) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass:
                                "badge badge-soft-secondary font-size-12",
                              class: {
                                "badge-soft-success":
                                  `${data.value}` === "ACTIVATED",
                                "badge-soft-danger":
                                  `${data.value}` === "NOT_ACTIVATED",
                                "badge-soft-warning":
                                  `${data.value}` === "PENDING",
                                "badge-soft-danger":
                                  `${data.value}` === "DEACTIVATED",
                              },
                            },
                            [
                              data.value === "ACTIVATED"
                                ? _c("span", [_vm._v(" ACTIVATED ")])
                                : _vm._e(),
                              data.value === "NOT_ACTIVATED"
                                ? _c("span", [_vm._v(" NOT ACTIVATED ")])
                                : _vm._e(),
                              data.value === "PENDING"
                                ? _c("span", [_vm._v(" PENDING ")])
                                : _vm._e(),
                              data.value === "DEACTIVATED"
                                ? _c("span", [_vm._v(" DEACTIVATED ")])
                                : _vm._e(),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(wallet)",
                      fn: function (data) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm.formatNumbers(data.value.balance)) +
                              " "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  667226284
                ),
              }),
            ],
            1
          ),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c(
                "div",
                {
                  staticClass:
                    "dataTables_paginate paging_simple_numbers float-right",
                },
                [
                  _c(
                    "ul",
                    { staticClass: "pagination pagination-rounded mb-0" },
                    [
                      _c("b-pagination", {
                        attrs: {
                          "total-rows": _vm.rows,
                          "per-page": _vm.perPage,
                        },
                        on: { change: _vm.changePagination },
                        model: {
                          value: _vm.currentPage,
                          callback: function ($$v) {
                            _vm.currentPage = $$v
                          },
                          expression: "currentPage",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }