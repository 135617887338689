var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Layout", [
    _vm.isPageLoading
      ? _c("div", { staticClass: "row pt-5" }, [
          _c(
            "div",
            { staticClass: "col-md-12 text-center p-5" },
            [
              _c("b-spinner", {
                staticClass: "m-2",
                staticStyle: { width: "5rem", height: "5rem" },
                attrs: { variant: "mid", role: "status" },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    !_vm.isPageLoading &&
    _vm.loggedInUser.accessLevel === _vm.accessLevelEnum.SUPER_ADMIN
      ? _c(
          "div",
          [
            _c("PageHeader", {
              attrs: { title: "Admin Panel", items: _vm.computedItems },
            }),
            _c("div", { staticClass: "row mt-2" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body" }, [
                    _c("div", { staticClass: "card-title" }, [
                      _vm._v(" Organization Overview"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "card-text mt-4" },
                      [
                        _c(
                          "b-tabs",
                          { attrs: { "content-class": "p-2" } },
                          [
                            _c(
                              "b-tab",
                              {
                                attrs: { lazy: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "title",
                                      fn: function () {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-none d-sm-inline-block",
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "bx bx-home",
                                                staticStyle: {
                                                  "font-size": "medium",
                                                },
                                              }),
                                              _vm._v(" Organizations "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "badge bg-primary rounded-pill text-white ml-1",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.organizationSummary
                                                          .numberOfActiveCompanies
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3463947637
                                ),
                              },
                              [_c("ActiveOrganizations")],
                              1
                            ),
                            _c(
                              "b-tab",
                              {
                                attrs: { lazy: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "title",
                                      fn: function () {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-none d-sm-inline-block",
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "bx bx-home",
                                                staticStyle: {
                                                  "font-size": "medium",
                                                },
                                              }),
                                              _vm._v(" New Registrations "),
                                              _vm.organizationSummary
                                                .numberOfNewCompanies > 0
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "badge bg-success rounded-pill text-white ml-1",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm
                                                              .organizationSummary
                                                              .numberOfNewCompanies
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1477258883
                                ),
                              },
                              [_c("NewOrganizations")],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
          ],
          1
        )
      : _vm._e(),
    !_vm.isPageLoading &&
    _vm.loggedInUser.accessLevel !== _vm.accessLevelEnum.SUPER_ADMIN
      ? _c("div", { staticClass: "row pt-5" }, [
          _c("div", { staticClass: "col-md-3" }),
          _c("div", { staticClass: "col-md-6 text-center" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/no-access.png"),
                alt: "",
                height: "50",
              },
            }),
            _c("div", { staticClass: "mt-3" }, [
              _vm._v(" " + _vm._s(_vm.$t("adminPanel.noPermision")) + " "),
            ]),
          ]),
          _c("div", { staticClass: "col-md-3" }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }