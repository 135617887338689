var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "layout-wrapper" } },
    [
      _c("NavBar"),
      _c("SideBar", { attrs: { "is-condensed": _vm.isMenuCondensed } }),
      _c(
        "div",
        { staticClass: "main-content" },
        [
          _c("div", { staticClass: "page-content" }, [
            _c(
              "div",
              { staticClass: "container-fluid" },
              [_vm._t("default")],
              2
            ),
          ]),
          _c("Footer"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }