<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: ""
    },
    items: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  computed: {
    getTranslationByKey(key) {
      return this.$t(key);
    },
    computedItems(items) {
      var computedItems = [];
      for (const item of items) {
        var revampedItem = item;
        computedItems.push(revampedItem);
      }
      return computedItems;
    }
  }
};
</script>

<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div
        class="page-title-box d-flex align-items-center justify-content-between"
      >
        <h4 class="mb-0 font-size-18">{{ title }}</h4>

        <div class="page-title-right d-none d-lg-block">
          <b-breadcrumb :items="items" class="m-0"></b-breadcrumb>
        </div>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>
