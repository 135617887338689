7<script>
import EditOrganizationOverlay from '../overlays/edit-organization-overlay.vue';
import { getNumbersAccoringTolanguage } from "@/shared/util/helpers.js";

export default {
  props: {
    organization: Object,
    organizationAdmins: Array,
    organizationStats: Object
  },
  components: {
    EditOrganizationOverlay
  },
  data() {
    return {
      /***** Data variables *****/
      dataFetchingnErrorMessage: null,
      /****** Operational variable ******/
      isFetchingDataError: false,
      isDisplayEditOrganizationOverlay: false
    }
  },
  mounted() {
  },
  methods: {
    clickEditOrganization() {
      this.isDisplayEditOrganizationOverlay = true
    },
    formatNumbers(numberToFormat) {
      const langaugeToFormat = window.localStorage.getItem("locale");
      return getNumbersAccoringTolanguage(numberToFormat, langaugeToFormat);
    },
  }
}
</script>

<template>
  <div>
    <div class="row mt-2">
      <div class="col-md-10">
        <span class="mr-2">
          <img
            v-if="organization && organization.image"
            :src="organization.image"
            class="header-profile-user"
            style="width: 50px; height: 50px"
          />
          <img
            v-else
            src="https://redem-resources.s3.eu-central-1.amazonaws.com/default-images/dafault-company.jpg"
            class="header-profile-user"
            style="width: 50px; height: 50px"
          />
        </span>
        <span class="mr-2" style="font-size: medium; font-weight: 600"> {{organization.name}} </span>
        <span> <i class='bx bxs-pencil' style="font-size: medium; cursor: pointer;" @click="clickEditOrganization"></i> </span>
      </div>

      <div class="col-md-2 d-flex align-items-center text-center justify-content-between">
        <div>
          {{$t("adminComponents.organizationDetailsHeader.numberOfDataImports")}}
          <h5 style="font-weight: 600; margin-bottom: 0;">{{ formatNumbers(organizationStats.numberOfDataImports) }}</h5>
        </div>

        <div>
          {{$t("adminComponents.organizationDetailsHeader.numberOfDataRecords")}}
          <h5 style="font-weight: 600; margin-bottom: 0;"> {{ formatNumbers(organizationStats.numberOfDataRecords) }} </h5>
        </div>

        <!-- <b-button variant="light">
          <i class='bx bx-dots-vertical-rounded' style="font-size: large;"></i>
        </b-button> -->
      </div>
    </div>

     <!-- Edit Organization Overlay -->
     <EditOrganizationOverlay
      v-if="isDisplayEditOrganizationOverlay === true"
      :visible="isDisplayEditOrganizationOverlay"
      @close="isDisplayEditOrganizationOverlay = false"
      :organization="organization"
      :candidatesForOrganizationAdmin="organizationAdmins"
    />

  </div>
</template>

