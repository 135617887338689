<script>
import AuthService from "@/api/services/auth.service";
import AccessLevel from "@/shared/enums/aceessLevels";
import { layoutMethods } from "@/state/helpers";
import MetisMenu from "metismenujs/dist/metismenujs";
// import Snowf from "vue-snowf";

export default {
  components: {
    // Snowf
  },
  data() {
    return {
      loggedInUser: {},
      accessLevelEnum: AccessLevel
    };
  },
  mounted: function () {
    // get logged in user to check the userAccessLevel
    this.getLoggedUser();

    document.body.setAttribute("data-sidebar", "dark");
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4) parent4.classList.add("mm-show");
            const parent5 = parent4.parentElement;
            if (parent5) parent5.classList.add("mm-active");
          }
        }
      }
    }
  },
  methods: {
    ...layoutMethods,
    async getLoggedUser() {
      let userLoggedIn = await AuthService.getLoggedInUser();

      if (userLoggedIn) {
        this.loggedInUser = userLoggedIn;
      }
    },
    change_layout(layout) {
      return this.changeLayoutType({ layoutType: layout });
    },
    lightSidebar() {
      document.body.setAttribute("data-topbar", "dark");
      document.body.removeAttribute("data-sidebar");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar-size", "small");
      document.body.classList.remove("vertical-collpsed");
    },
    compactSidebar() {
      document.body.setAttribute("data-sidebar-size", "small");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.classList.remove("vertical-collpsed");
      document.body.removeAttribute("data-topbar", "dark");
    },
    iconSidebar() {
      document.body.setAttribute("data-keep-enlarged", "true");
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-topbar", "dark");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    boxedLayout() {
      document.body.setAttribute("data-keep-enlarged", "true");
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar", "colored");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-topbar", "dark");
    },
    coloredSidebar() {
      document.body.setAttribute("data-sidebar", "colored");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar-size", "small");
      document.body.classList.remove("vertical-collpsed");
    }
  }
};
</script>

<template>
  <!-- ========== Left Sidebar Start ========== -->

  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- <Snowf
      :amount="50"
      :size="4"
      :speed="1.5"
      :wind="0"
      :opacity="0.8"
      :swing="1"
      :image="null"
      :zIndex="null"
      :resize="true"
      color="#fff"
    /> -->

    <ul id="side-menu" class="metismenu list-unstyled">
      <li>
        <router-link tag="a" to="/" class="side-nav-link-ref">
          <i class="bx bx-home-circle"></i>
          <span> {{ $t("menus.sideMenu.dashboard") }} </span>
        </router-link>
      </li>

      <li class="d-none d-lg-block">
        <a href="javascript:void(0);" class="is-parent">
          <i class="bx bx-import"></i>
          <span>{{ $t("menus.sideMenu.importSection.mainTitle") }}</span>
        </a>
        <ul class="sub-menu" aria-expanded="false">
          <li>
            <router-link
              tag="a"
              to="/upload-dataset/step1"
              class="side-nav-link-ref"
            >
              <i class="bx bxs-to-top"></i>
              <span>
                {{ $t("menus.sideMenu.importSection.subTitle.upload") }}
              </span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/live-import" class="side-nav-link-ref">
              <i class="bx bx-code-alt"></i>
              <span>
                {{ $t("menus.sideMenu.importSection.subTitle.live") }}
              </span>
              <!-- TODO: enable when beta -->
              <!-- <span class="badge bg-warning float-end ml-2">Beta</span> -->
            </router-link>
          </li>
        </ul>
      </li>

      <li>
        <router-link tag="a" to="/datafiles" class="side-nav-link-ref">
          <i class="bx bxs-data"></i>
          <span> {{ $t("menus.sideMenu.projectSection.mainTitle") }} </span>
        </router-link>
      </li>

      <li>
        <router-link tag="a" to="/administration" class="side-nav-link-ref" v-if="this.loggedInUser.accessLevel !== accessLevelEnum.EMPLOYEE">
          <i class="bx bx-cog"></i>
          <span> Administration </span>
        </router-link>
      </li>

      <li v-if="this.loggedInUser.accessLevel === accessLevelEnum.SUPER_ADMIN">
        <router-link tag="a" to="/admin-panel" class="side-nav-link-ref">
          <i class="bx bx-ghost"></i>
          <span> Admin Panel </span>
        </router-link>
      </li>


      <li v-if="this.loggedInUser.accessLevel === accessLevelEnum.SUPER_ADMIN">
        <router-link tag="a" to="/monitoring" class="side-nav-link-ref">
          <i class="bx bx-show"></i>
          <span> Monitoring </span>
        </router-link>
      </li>

      <li
        class="d-none d-lg-block"
        v-if="this.loggedInUser.accessLevel === accessLevelEnum.SUPER_ADMIN"
      >
        <a href="javascript:void(0);" class="is-parent">
          <i class="bx bx-stats"></i>
          <span>Stats</span>
          <span class="badge bg-warning float-end ml-2">KPI</span>
        </a>
        <ul class="sub-menu" aria-expanded="false">
          <li>
            <router-link
              tag="a"
              to="/data-files-stats"
              class="side-nav-link-ref"
            >
              <i class="bx bx-folder"></i>
              <span> Data File Stats </span>
            </router-link>
          </li>
          <li>
            <router-link
              tag="a"
              to="/data-records-stats"
              class="side-nav-link-ref"
            >
              <i class="bx bx-group"></i>
              <span> Data Records Stats </span>
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <!-- Sidebar -->
</template>
