<script>
import { getCreditsAccoringTolanguage, getNumbersAccoringTolanguage, getErrorMessageByErrorCode, getDateAccoringTolanguage } from "@/shared/util/helpers.js";
import TransactionService from "@/api/services/transaction.service.js";
import { EventBus } from "@/shared/util/event-bus.js";

export default {
  props: {
    organization: Object
  },
  data() {
    return {
      /***** Data variables *****/
      transactions: null,
      dataForTable: null,
      showAllReceivedTransactions: true,
      showAllUsedTransactions: true,
      /****** Operational variable ******/
      isComponentLoading: false,
      isFetchingDataError: false,
      dataFetchingnErrorMessage: null,
      /***** Table Data *****/
      fieldsForTable: [
        { 
          key: "id",
          label: "ID",
          thClass: "d-none",
          tdClass: "d-none"
        },
        {
          key: "date",
          label: this.$t("adminComponents.transactionWidget.tableHeaders.date"),
        },
        {
          key: "description",
          label: this.$t("adminComponents.transactionWidget.tableHeaders.description"),
        },
        {
          key: "amount",
          label: this.$t("adminComponents.transactionWidget.tableHeaders.amount"),
        },
        {
          key: "numberOfUpdates",
          label: this.$t("adminComponents.transactionWidget.tableHeaders.numberOfUpdates"),
        },
        {
          key: "type",
          label: this.$t("adminComponents.transactionWidget.tableHeaders.type"),
        },
      ],
      currentPage: 1,
      perPage: 8
      ,
      filter: null
    }
  },
  computed: {
    rows() {
      return this.transactions.length;
    },
  },
  async mounted() {
    this.isComponentLoading = true;
    await this.fetchTransactions();
    this.isComponentLoading = false;
    EventBus.$emit("creditAndTransactionComponetGetLoaded");
  },
  methods: {
    async fetchTransactions() {
      try {
        this.transactions = null;

        this.transactions = await TransactionService.getCompanyTransactions(this.organization.id);
        await this.setDataForTable();

      } catch(error) {
        this.isFetchingDataError = true;
        this.dataFetchingnErrorMessage = await getErrorMessageByErrorCode(error.code);
      }
    },
    setDataForTable() {
      this.dataForTable = []

      this.transactions.forEach(transaction => {
        let transactionToPush = {
          id: transaction.id,
          date: transaction.date,
          description: transaction.description,
          amount: transaction.amount,
          numberOfUpdates: transaction.numberOfUpdates,
          type: transaction.type
        }

        if(transactionToPush.type === 'RECEIVED' && this.showAllReceivedTransactions) {
          this.dataForTable.push(transactionToPush)
        }

        if(transactionToPush.type === 'USED' && this.showAllUsedTransactions) {
          this.dataForTable.push(transactionToPush)
        }
      });
    },
    formatCredit(numberToFormat) {
      const langaugeToFormat = window.localStorage.getItem("locale");
      return getCreditsAccoringTolanguage(numberToFormat, langaugeToFormat);
    },
    formatNumber(numberToFormat) {
      const langaugeToFormat = window.localStorage.getItem("locale");
      return getNumbersAccoringTolanguage(numberToFormat, langaugeToFormat);
    },
    formatDate(dateToFormat) {
      const langaugeToFormat = window.localStorage.getItem("locale");
      return getDateAccoringTolanguage(dateToFormat, langaugeToFormat);
    },
    async selectAllReceivedTransactions() {
      await this.setDataForTable();
    },
    async selectAllUsedTransactions() {
      await this.setDataForTable();
    }
  }
}
</script>

<template>
  <div class="card" style="height: 610px">
    <!-- Component Loading -->
    <div class="card-body" v-if="isComponentLoading">
      <div class="card-text">
        <div class="row pt-5">
          <div class="col-md-12 text-center p-5">
            <b-spinner
              style="width: 5rem; height: 5rem"
              class="m-2"
              variant="mid"
              role="status"
            ></b-spinner>
          </div>
        </div>
      </div>
    </div>

    <!-- Display Transactions -->
    <div class="card-body" v-if="!isComponentLoading && this.transactions && this.transactions.length > 0">
      <div class="card-text">
        
        <div class="row">
          <div class="col-8">
            <span style="font-size: medium; font-weight: 600"> {{$t("adminComponents.transactionWidget.title")}} </span>
          </div>
          <div class="col-4 text-right">
            <!-- <b-button variant="light" size="sm" @click="clickExport">
              <i class='bx bx-export mr-2' style="font-size: medium;"></i> {{ $t("adminComponents.transactionWidget.exportButton")}}
            </b-button> -->
          </div>
        </div>

        <!-- Search Bar & Fliters -->
        <div class="row mt-4">
          <div class="col-3">
            <b-form-input v-model="filter" type="search" :placeholder="$t('adminComponents.transactionWidget.searchPlaceholder')" ></b-form-input>
          </div>
          <div class="col-9 text-right">
            <b-form-checkbox
              v-model="showAllReceivedTransactions"
              @change="selectAllReceivedTransactions"
              style="display: inline-block; margin-right: 10px; text-align: right;"
            >
              {{$t("adminComponents.transactionWidget.transactionTypes.received")}}
            </b-form-checkbox>

            <b-form-checkbox
              v-model="showAllUsedTransactions"
              @change="selectAllUsedTransactions"
              style="display: inline-block; margin-right: 10px; text-align: right;"
            >
              {{$t("adminComponents.transactionWidget.transactionTypes.used")}}
            </b-form-checkbox>
          </div>
        </div>

        <!-- Data Table -->
        <div class="mt-4">
          <div class="table-responsive mb-0">
            <b-table
              :items="dataForTable"
              :fields="fieldsForTable"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :bordered="true"
              :filter="filter"
              :filter-included-fields="['description']"
            >
              <!-- Date Column -->
              <template v-slot:cell(date)="data">
                {{ formatDate(data.value) }}
              </template>

              <!-- Amount Column -->
              <template v-slot:cell(amount)="data">
                {{ formatCredit(data.value) }}
              </template>

              <!-- Number Of Updates Column -->
              <template v-slot:cell(numberOfUpdates)="scope">
                <span v-if="scope.item.type === 'RECEIVED'"> - </span>
                <span v-if="scope.item.type === 'USED'">
                  {{ formatNumber(scope.item.numberOfUpdates) }}
                </span>
              </template>

              <!-- Type -->
              <template v-slot:cell(type)="data">
                <span
                  class="badge badge-soft-secondary font-size-12"
                  :class="{
                    'badge-soft-success': `${data.value}` === 'RECEIVED',
                    'badge-soft-danger': `${data.value}` === 'USED'
                  }"
                >
                  <span v-if="data.value === 'USED'"> {{$t("adminComponents.transactionWidget.transactionTypes.used")}} </span>
                  <span v-if="data.value === 'RECEIVED'"> {{$t("adminComponents.transactionWidget.transactionTypes.received")}} </span>
                </span>
              </template>
            </b-table>
        </div>
        </div>

        <!-- pagination -->
        <div class="row">
          <div class="col">
            <div
              class="
                dataTables_paginate
                paging_simple_numbers
                float-right
              "
            >
              <ul class="pagination pagination-rounded mb-0">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- Display Empty Transactions -->
    <div class="card-body" v-if="!isComponentLoading && this.transactions.length === 0 && !this.isFetchingDataError">
      <div class="card-text">
        <div class="row pt-5">
          <div class="col-12 text-center">
            <img src="@/assets/images/information.png" class="img-fluid mb-4 mt-3" style="width: 60px" />
            <h6>
              {{$t("adminComponents.transactionWidget.noTransactionsToDisplay.title")}}
            </h6>
            <p>
              {{$t("adminComponents.transactionWidget.noTransactionsToDisplay.text1")}}<br/>
              {{$t("adminComponents.transactionWidget.noTransactionsToDisplay.text2")}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Display Empty Transactions -->
    <div class="card-body" v-if="!isComponentLoading && this.isFetchingDataError">
      <div class="card-text">
        <div class="row pt-5">
          <div class="col-12 text-center">
            <img src="@/assets/images/red-warning.png" class="img-fluid mb-4 mt-3" style="width: 60px" />
            <h6>
              {{$t("adminComponents.transactionWidget.dataFetchingError")}}
            </h6>
            <p>
              {{ this.dataFetchingnErrorMessage }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>