<script>
export default {
  data() {
    return {
    }
  }
}
</script>
<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-8">
          {{ new Date().getFullYear() }} © Redem GmbH | 
          <a href="https://redem.io/terms-and-conditions/" target="_blank"> {{$t("general.footer.termsAndCondition")}} </a> |
          <a href="https://redem.io/privacy-policy/" target="_blank"> {{$t("general.footer.dataPrivacyPolicy")}} </a> </div>
        <div class="col-sm-4">
          <div class="text-sm-right d-none d-sm-block">
            <p>V{{ this.$store.state.appVersion }}</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
