<script>

export default {
  data() {
    return {
      /***** Data variables *****/
      /****** Operational variable ******/
    }
  },
  methods: {
  }
}
</script>

<template>
  <div>
    New Organizations
    <!-- Component Loading -->

    <!-- No New Organizations -->

    <!-- Display new organizations in Table -->
  </div>
</template>

