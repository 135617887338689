var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        centered: "",
        "hide-footer": "",
        "hide-header": "",
        "no-close-on-backdrop": "",
        size: "lg",
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("div", { staticClass: "row text-center justify-content-center" }, [
        _c("div", { staticClass: "col-8 mt-3" }, [
          _c("img", {
            staticClass: "img-fluid mb-4 mt-3",
            staticStyle: { width: "250px" },
            attrs: {
              src: require("@/assets/images/register-image.png"),
              alt: "",
            },
          }),
          _c("h5", [_vm._v(_vm._s(_vm.$t("general.getStartedModal.title")))]),
          _c("p", [
            _vm._v(
              " " + _vm._s(_vm.$t("general.getStartedModal.descrption")) + " "
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "p-3 col-md-12 text-lg-center" },
          [
            _c(
              "b-button",
              {
                attrs: { variant: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.clickGetStarted()
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(_vm.$t("general.getStartedModal.button.getStarted"))
                ),
              ]
            ),
            _c(
              "b-button",
              {
                staticClass: "ml-2",
                attrs: { variant: "outline-primary" },
                on: {
                  click: function ($event) {
                    return _vm.clickViewTutorial()
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(_vm.$t("general.getStartedModal.button.tutorial"))
                ),
              ]
            ),
            _c(
              "b-button",
              {
                staticClass: "ml-2",
                attrs: { variant: "light" },
                on: {
                  click: function ($event) {
                    _vm.showModal = false
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("general.getStartedModal.button.cancel")))]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }