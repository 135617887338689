import ResponseStatus from "@/shared/enums/responseStatus";
import { ApiCall } from "../apiBase";
import CustomErrorMessage from "@/shared/util/customErrorMessage.js";

class TransactionService {
  /************* get All Transaction for Compnay*********/
  async getCompanyTransactions(companyId) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getCompanyTransactions($companyId: ID!){
            getCompanyTransactions(companyId: $companyId) {
              id,
              amount,
              type,
              description,
              date,
              datafileId,
              numberOfUpdates
            }
          }
        `,
        variables: {
          companyId: companyId
        }
      }
    });

    if (response?.data?.data?.getCompanyTransactions && !response?.data?.errors) {
      return response?.data?.data?.getCompanyTransactions;
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      );
    }
  }

  /******** Refund Transaction ******/
  async refundTransaction(transactionId) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation refundTransaction($input: ID!) {
            refundTransaction(transactionId: $input) {
              status
              message
            }
          }
        `,
        variables: {
          input: transactionId
        }
      }
    });

    if (result?.data?.data?.refundTransaction && !result?.data?.errors) {
      return ResponseStatus.SUCCESS;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }
}

export default new TransactionService();
