var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vertical-menu" },
    [
      !_vm.isCondensed
        ? _c(
            "simplebar",
            { staticClass: "h-100", attrs: { settings: _vm.settings } },
            [_c("SideNav")],
            1
          )
        : _c("simplebar", { staticClass: "h-100" }, [_c("SideNav")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }