<script>
import InviteUserOverlay from '../overlays/invite-user-overlay.vue'
import ActiveUserCard from './active-user-card.vue'
import PendingUserCard from './pending-user-card.vue'
import CompanyService from "@/api/services/company.service.js";
import { getErrorMessageByErrorCode } from "@/shared/util/helpers.js";
import { EventBus } from "@/shared/util/event-bus.js";
import DeleteUserOverlay from '../overlays/delete-user-overlay.vue'

export default {
  props: {
    organization: Object,
    loggedInUser: Object
  },
  components: {
    ActiveUserCard,
    PendingUserCard,
    InviteUserOverlay,
    DeleteUserOverlay
  },
  data() {
    return {
      /***** Data variables *****/
      users: null,
      activeUsers: null,
      pendingUsers: null,
      dataFetchingnErrorMessage: null,
      userToDelete: null,
      /****** Operational variable ******/
      isComponentLoading: false,
      isFetchingDataError: false,
      isDisplayInviteUserOverlay: false,
      isDisplayDeleteUserOverlay: false
    }
  },
  async mounted() {
    this.isComponentLoading = true;
    await this.getUsersOfOrganization();
    this.isComponentLoading = false;
  },
  created() {
    EventBus.$on("refreshUserManagementTab", async () => {
      await this.refreshUserManagementTab();
    });

    EventBus.$on("deleteActiveUser", (user) => {
      this.userToDelete = user;
      this.isDisplayDeleteUserOverlay = true;
    });
  },
  methods: {
    clickInviteUser() {
      this.isDisplayInviteUserOverlay = true
    },
    async getUsersOfOrganization() {
      try {
        this.users = null
        this.activeUsers = []
        this.pendingUsers = []

        this.users = await CompanyService.getUsersOfCompany(this.organization.id);

        if(this.users && this.users.length > 0) {
          for(let i=0; i < this.users.length; i++) {
            if(this.users[i].status === 'ACTIVATED') {
              this.activeUsers.push(this.users[i]);
            } else {
              this.pendingUsers.push(this.users[i]);
            }
          }
        }
      } catch (error) {
        this.isFetchingDataError = true;
        this.dataFetchingnErrorMessage = await getErrorMessageByErrorCode(error.code);
      }
    },
    async refreshUserManagementTab() {
      // close invite user overlay
      this.isDisplayInviteUserOverlay = false

      // Enebale component loader
      this.isComponentLoading = true;

      // fetch all users
      await this.getUsersOfOrganization();

      // Disable component loader
      this.isComponentLoading = false;
    }
  }
}
</script>

<template>
  <div>

    <!-- Component Loading -->
    <div
      class="row pt-5"
      v-if="isComponentLoading"
    >
      <div class="col-md-12 text-center p-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>

    <!-- Display Data -->
    <div v-if="!isComponentLoading && users && users.length > 0" class="animate__animated animate__fadeInUp animate__slow">
      <!-- Active Users Title -->
      <div class="row mt-3">
        <div class="col-md-9">
          <span class="mr-2" style="font-size: medium; font-weight: 600"> {{ $t("adminComponents.manageUsersWidget.activeUsers") }} </span>
        </div>

        <div class="col-md-3 text-right">
          <b-button variant="light" @click="clickInviteUser">
            <i class='bx bx-user-plus mr-2' style="font-size: medium;"></i> {{ $t("adminComponents.manageUsersWidget.inviteUserButton") }}
          </b-button>
        </div>
      </div>

      <!-- Active Users -->
      <div class="row mt-2">
        <div class="col-md-4" v-for="(user, userIndex) in activeUsers" :key="userIndex">
          <ActiveUserCard :user="user" :organizationOwner="organization.companyOwner" :loggedInUser="loggedInUser"/>
        </div>
      </div>

      <!-- Pending Users Title -->
      <div class="row mt-3" v-if="pendingUsers && pendingUsers.length > 0">
        <div class="col-md-12">
          <hr />
        </div>
        <div class="col-md-12">
          <span class="mr-2" style="font-size: medium; font-weight: 600"> {{ $t("adminComponents.manageUsersWidget.pendingUsers") }} </span>
        </div>
      </div>

      <!-- Pending Users -->
      <div class="row mt-3" v-if="pendingUsers && pendingUsers.length > 0">
        <div class="col-md-4" v-for="(user, userIndex) in pendingUsers" :key="userIndex">
          <PendingUserCard :user="user" />
        </div>
      </div>
    </div>

    <!-- Display Error -->
    <div class="row pt-5" v-if="!isComponentLoading && isFetchingDataError">
      <div class="col-md-12 text-center">
        {{ this.dataFetchingnErrorMessage }}
      </div>
    </div>

    <!-- Invite User Overlay -->
    <InviteUserOverlay
      v-if="isDisplayInviteUserOverlay === true"
      :visible="isDisplayInviteUserOverlay"
      @close="isDisplayInviteUserOverlay = false"
      :organization="this.organization"
    />

    <!-- Delete User Overlay -->
    <DeleteUserOverlay
      v-if="isDisplayDeleteUserOverlay === true"
      :visible="isDisplayDeleteUserOverlay"
      @close="isDisplayDeleteUserOverlay = false"
      :user="this.userToDelete"
    />
  </div>
</template>

