import { trafficLightColorDecider } from "@/shared/util/chart-helper";
import { creditColorDecider } from "../util/chart-helper";

export const trafficLightChart = {
  chartOptions: {
    chart: {
      id: "RedemAmpel",
      toolbar: {
        show: true
      }
    },
    labels: ["0 - 40", "40 - 55", "55 - 80", "80 - 100", "Invalid"],
    colors: ["#f46a6a", "#ffcc44", "#a5e79a", "#34c38f", "#adb5bd"],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 280
          },
          legend: {
            show: true
          }
        }
      }
    ],
    tooltip: {
      y: {
        formatter: function(value) {
          return value.toFixed(2) + "%";
        }
      }
    }
  }
};

export const answerQualityChart = {
  chartOptions: {
    chart: {
      id: "Mittelwert",
      height: 350,
      type: "radialBar",
      toolbar: {
        show: true
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -180,
        endAngle: 180,
        hollow: {
          margin: 0,
          size: "65%",
          background: "#fff",
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: "front",
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.1
          }
        },
        track: {
          background: "#fff",
          strokeWidth: "67%",
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.2
          }
        },

        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: false,
            color: "#005181",
            fontSize: "15px"
          },
          value: {
            formatter: function(val) {
              return parseInt(val);
            },
            color: "#111",
            fontSize: "30px",
            show: true
          }
        }
      }
    },
    fill: {
      type: "solid"
    },
    colors: [
      function({ value }) {
        return trafficLightColorDecider(value);
      }
    ],
    stroke: {
      lineCap: "round"
    },
    responsive: [
      {
        breakpoint: 1441,
        options: {
          chart: {
            height: 190
          }
        }
      }
    ]
  }
};

export const averagePrimayScoreChart = {
  chartOptions: {
    chart: {
      id: "Avgx",
      height: 350,
      type: "radialBar",
      toolbar: {
        show: true
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -180,
        endAngle: 180,
        hollow: {
          margin: 0,
          size: "65%",
          background: "#fff",
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: "front",
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.1
          }
        },
        track: {
          background: "#fff",
          strokeWidth: "67%",
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.2
          }
        },

        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: false,
            color: "#005181",
            fontSize: "15px"
          },
          value: {
            formatter: function(val) {
              return parseInt(val);
            },
            color: "#111",
            fontSize: "30px",
            show: true
          }
        }
      }
    },
    fill: {
      type: "solid"
    },
    colors: [
      function({ value }) {
        return trafficLightColorDecider(value);
      }
    ],
    stroke: {
      lineCap: "round"
    },
    responsive: [
      {
        breakpoint: 1441,
        options: {
          chart: {
            height: 190
          }
        }
      }
    ]
  }
};

export const BTSDistributionChart = {
  chartOptions: {
    chart: {
      id: "StreuungderRedemScores",
      height: 320,
      type: "line",
      toolbar: { show: true },
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: [
        "0-9",
        "10-19",
        "20-29",
        "30-39",
        "40-49",
        "50-59",
        "60-69",
        "70-79",
        "80-89",
        "90-100"
      ]
    },
    colors: ["#005181"],
    stroke: {
      curve: "smooth",
      width: 3
    }
  }
};

export function AverageDistributionChart() {
  this.chartOptions = {
    chart: {
      height: 350,
      type: "line",
      toolbar: { show: false },
      zoom: {
        enabled: false
      }
    },
    annotations: {
      xaxis: [
        {
          x: 0,
          borderColor: "#005181",
          yAxisIndex: 0,
          strokeDashArray: 0,
          label: {
            text: "Projection",
            orientation: "horizontal",
            textAnchor: "start",
            style: {
              color: "#000",
              background: "#005181"
            }
          }
        },
        {
          x: 0,
          borderColor: "#777",
          yAxisIndex: 0,
          strokeDashArray: 0,
          label: {
            position: "top",
            orientation: "vertical",
            text: "Average Distribution",
            style: {
              color: "#000",
              background: "#777"
            }
          }
        }
      ]
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: [],
      labels: {
        show: true,
        rotate: 0,
        rotateAlways: false,
        hideOverlappingLabels: false,
        trim: true,
        style: {
          fontSize: "12px"
        }
      }
    },
    colors: ["#005181"],
    stroke: {
      curve: "smooth",
      width: 3
    }
  };
}

export function defualtResultChart() {
  this.chartOptions = {
    chart: {
      id: "ErgebnisDiagramme",
      toolbar: {
        show: true
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "25%",
        endingShape: "flat"
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"]
    },
    colors: ["#adb5bd", "#005181", "#74788D", "#34c38f"],
    xaxis: {
      categories: [],
      labels: {
        show: true,
        rotate: 0,
        rotateAlways: false,
        hideOverlappingLabels: false,
        trim: true,
        style: {
          fontSize: "12px"
        },
        formatter: value => {
          var len = value.length;
          return len > 10 ? value.substring(0, 20) + "..." : value;
        }
      }
    },
    grid: {
      borderColor: "#f1f1f1"
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      x: {
        formatter: val => {
          return val.replace(/(.{80})/g, "$1<br>");
        }
      }
    }
  };
}

export const trafficLightQualityChartOptions = {
  chart: {
    id: "Antwortqualität",
    stacked: true,
    toolbar: {
      show: true
    },
    zoom: {
      enabled: true
    }
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "12%",
      endingShape: "rounded"
    }
  },
  dataLabels: {
    enabled: false
  },
  xaxis: {
    categories: [],
    labels: {
      show: true,
      rotate: 0,
      rotateAlways: false,
      hideOverlappingLabels: false,
      trim: true,
      style: {
        fontSize: "12px"
      },
      formatter: value => {
        var len = value.length;
        return len > 10 ? value.substring(0, 8) + "..." : value;
      }
    }
  },
  colors: ["#f46a6a", "#ffcc44", "#a5e79a", "#34c38f"],
  legend: {
    position: "bottom"
  },
  fill: {
    opacity: 1
  },
  tooltip: {
    shared: false,
    y: {
      formatter: val => {
        return val;
      }
    }
  }
};

export const numberOfQualityScoresChartOptions = {
  chart: {
    id: "Antwortqualität",
    stacked: true,
    toolbar: {
      show: true
    },
    zoom: {
      enabled: true
    }
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "12%",
      endingShape: "rounded"
    }
  },
  dataLabels: {
    enabled: false
  },
  xaxis: {
    categories: [],
    labels: {
      show: true,
      rotate: 0,
      rotateAlways: false,
      hideOverlappingLabels: false,
      trim: true,
      style: {
        fontSize: "12px"
      },
      formatter: value => {
        var len = value.length;
        return len > 10 ? value.substring(0, 8) + "..." : value;
      }
    }
  },
  colors: ["#FF7F7F", "#90b7ff", "#a5e79a", "#ffd46c", "#77009a"],
  legend: {
    position: "bottom"
  },
  fill: {
    opacity: 1
  },
  tooltip: {
    shared: false,
    x: {
      formatter: val => {
        return val;
      }
    },
    y: {
      formatter: val => {
        return Math.round(val * 100) / 100;
      }
    }
  }
};

//OES category chart
export function OESCategoryChart() {
  this.chartOptions = {
    chart: {
      id: "OESCategoryChart",
      toolbar: {
        show: false
      }
    },
    colors: ["#34c38f", "#a5e79a","#ffce44",  "#ffe46d", "#50a5f1", "#6f42c1", "#FF7F7F", "#90b7ff",  "#77009a", "#adb5bd", "#005181", "#74788D", "#eecccc"],
    legend: {
      show: true,
      position: "left",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 280
          },
          legend: {
            show: true
          }
        }
      }
    ],
    tooltip: {
      y: {
        formatter: function(value) {
          return value.toFixed(2) + "%";
        }
      }
    }
  };
}

export function newTrafficLightChart() {
  this.chartOptions = {
    chart: {
      id: "TL",
      toolbar: {
        show: true
      }
    },
    labels: ["0 - 40", "40 - 55", "55 - 80", "80 - 100", "Invalid"],
    colors: ["#f46a6a", "#ffcc44", "#a5e79a", "#34c38f", "#adb5bd"],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 280
          },
          legend: {
            show: true
          }
        }
      }
    ],
    tooltip: {
      y: {
        formatter: function(value) {
          return value.toFixed(2) + "%";
        }
      }
    }
  };
}

export class DistributionChart {
  constructor() {
    this.chartOptions = {
      chart: {
        id: "Distribution",
        height: 320,
        type: "line",
        toolbar: { show: true },
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: [
          "0-9",
          "10-19",
          "20-29",
          "30-39",
          "40-49",
          "50-59",
          "60-69",
          "70-79",
          "80-89",
          "90-100"
        ]
      },
      colors: ["#005181"],
      stroke: {
        curve: "smooth",
        width: 3
      }
    };
  }
}

export function newMeanChart() {
  this.chartOptions = {
    chart: {
      id: "Avgx",
      height: 350,
      type: "radialBar",
      toolbar: {
        show: true
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -180,
        endAngle: 180,
        hollow: {
          margin: 0,
          size: "65%",
          background: "#fff",
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: "front",
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.1
          }
        },
        track: {
          background: "#fff",
          strokeWidth: "67%",
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.2
          }
        },

        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: false,
            color: "#005181",
            fontSize: "15px"
          },
          value: {
            formatter: function(val) {
              return parseInt(val);
            },
            color: "#111",
            fontSize: "30px",
            show: true
          }
        }
      }
    },
    fill: {
      type: "solid"
    },
    colors: [
      function({ value }) {
        return trafficLightColorDecider(value);
      }
    ],
    stroke: {
      lineCap: "round"
    },
    responsive: [
      {
        breakpoint: 1441,
        options: {
          chart: {
            height: 190
          }
        }
      }
    ]
  };
}

export function CreditHistoryChat() {
  this.chartOptions = {
    chart: {
      height: 300,
      type: "radialBar"
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            show: true,
            fontSize: "16px",
            fontWeight: 600,
            offsetY: -10
          },
          value: {
            show: true,
            fontSize: "14px",
            offsetY: 4,
            formatter: function(val) {
              return val + "%";
            }
          },
          total: {
            show: true,
            label: "Total",
            color: "#373d3f",
            fontSize: "16px",
            fontFamily: undefined,
            fontWeight: 600,
            formatter: function(w) {
              return (
                w.globals.seriesTotals.reduce(function(a, b) {
                  return a + b;
                }, 0) + "%"
              );
            }
          }
        }
      }
    },
    stroke: {
      lineCap: "round"
    },
    colors: ["#f46a6a", "#ffce44", "#34c38f", "#50a5f1", "#6f42c1"],
    labels: ["label1", "label2", "label3"],
    legend: {
      show: false
    }
  };
}

// Credit Chart
export function CreditChart() {
  this.chartOptions = {
    chart: {
      height: 400,
      type: "radialBar",
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: 0,
        endAngle: 360,
        hollow: {
          margin: 0,
          size: "70%",
          background: "#fff",
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: "front"
        },
        track: {
          background: "#f5f5f5",
          strokeWidth: "80%",
          margin: 0 // margin is in pixels
        },
        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: false,
            color: "#005181",
            fontSize: "15px"
          },
          value: {
            formatter: function(val) {
              return parseInt(val) + "%";
            },
            color: "#111",
            fontSize: "30px",
            show: true
          }
        }
      }
    },
    fill: {
      type: "solid"
    },
    colors: [
      function({ value }) {
        return creditColorDecider(value);
      }
    ],
    stroke: {
      lineCap: "round"
    }
  };
}
