<script>
import UserService from "@/api/services/user.service.js";

export default {
  props: {
    visible: Boolean,
    closeable: Boolean,
    user: Object
  },
  data() {
    return {
      /******** Data Variables ******/
      /******** Operational Variables *******/
    };
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  methods: {
    clickClose() {
      this.$emit("close");
    },
    async clickGetStarted() {
      const response = await UserService.updateGetStarted(this.user.id);
      if (response === "SUCCESS") {
        window
          .open(
            "https://help.redem.io/docs/get-started/first-stept-with-redem/",
            "_blank"
          )
          .focus();
        this.$emit("close");
      }
    },
    async clickViewTutorial() {
      const response = await UserService.updateGetStarted(this.user.id);
      if (response === "SUCCESS") {
        window
          .open(
            "https://www.youtube.com/watch?v=P5czyxQfbZw",
            "_blank"
          )
          .focus();
        this.$emit("close");
      }
    }
  }
};
</script>

<template>
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
    size="lg"
  >
    <div class="row text-center justify-content-center">
      <div class="col-8 mt-3">
        <img
          src="@/assets/images/register-image.png"
          alt
          class="img-fluid mb-4 mt-3"
          style="width: 250px"
        />
        <h5>{{ $t("general.getStartedModal.title") }}</h5>
        <p>
          {{ $t("general.getStartedModal.descrption") }}
        </p>
      </div>
      <div class="p-3 col-md-12 text-lg-center">
        <b-button variant="primary" @click="clickGetStarted()">{{
          $t("general.getStartedModal.button.getStarted")
        }}</b-button>
        <b-button variant="outline-primary" class="ml-2" @click="clickViewTutorial()">{{
          $t("general.getStartedModal.button.tutorial")
        }}</b-button>
        <b-button variant="light" @click="showModal = false" class="ml-2">{{
          $t("general.getStartedModal.button.cancel")
        }}</b-button>
      </div>
    </div>
  </b-modal>
</template>
