var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        centered: "",
        "hide-footer": "",
        "hide-header": "",
        "no-close-on-backdrop": "",
        size: "md",
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-10" }, [
          _c(
            "div",
            { staticStyle: { "font-size": "medium", "font-weight": "600" } },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("adminComponents.inviteUserOverlay.title")) +
                  " "
              ),
            ]
          ),
          _c("div", { staticClass: "mt-2" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("adminComponents.inviteUserOverlay.description")
                ) +
                " "
            ),
          ]),
        ]),
        _c("div", { staticClass: "col-md-2 text-right" }, [
          _c("i", {
            staticClass: "bx bx-x",
            staticStyle: { "font-size": "x-large", cursor: "pointer" },
            on: { click: _vm.clickClose },
          }),
        ]),
        _c("div", { staticClass: "col-md-12" }, [_c("hr")]),
      ]),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c(
            "b-alert",
            {
              staticClass: "mt-3 mb-3",
              attrs: { variant: "danger", dismissible: "" },
              model: {
                value: _vm.isInviteUserError,
                callback: function ($$v) {
                  _vm.isInviteUserError = $$v
                },
                expression: "isInviteUserError",
              },
            },
            [_vm._v(_vm._s(_vm.inviteUserErrorMessage))]
          ),
          _c(
            "b-alert",
            {
              staticClass: "mt-3 mb-3",
              attrs: { variant: "success" },
              model: {
                value: _vm.isInviteUserSuccess,
                callback: function ($$v) {
                  _vm.isInviteUserSuccess = $$v
                },
                expression: "isInviteUserSuccess",
              },
            },
            [
              _c("i", { staticClass: "bx bx-check-double bx-tada mr-1" }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("adminComponents.inviteUserOverlay.successMessage")
                  ) +
                  " "
              ),
            ]
          ),
          _c(
            "b-form-group",
            {
              attrs: { id: "email-group", "label-for": "email" },
              scopedSlots: _vm._u([
                {
                  key: "label",
                  fn: function () {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "adminComponents.inviteUserOverlay.form.email.label"
                            )
                          ) +
                          " "
                      ),
                      _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("b-form-input", {
                class: { "is-invalid": _vm.$v.email.$error },
                attrs: {
                  id: "email",
                  type: "email",
                  placeholder: _vm.$t(
                    "adminComponents.inviteUserOverlay.form.email.placeholder"
                  ),
                },
                model: {
                  value: _vm.email,
                  callback: function ($$v) {
                    _vm.email = $$v
                  },
                  expression: "email",
                },
              }),
              _vm.$v.email.$error
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    !_vm.$v.email.required
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "adminComponents.inviteUserOverlay.form.email.required"
                              )
                            )
                          ),
                        ])
                      : _vm._e(),
                    !_vm.$v.email.email
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "adminComponents.inviteUserOverlay.form.email.invalidEmail"
                              )
                            )
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: { id: "first-name-group", "label-for": "firstName" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "adminComponents.inviteUserOverlay.form.firstName.label"
                                  )
                                ) +
                                " "
                            ),
                            _c("span", { staticClass: "text-danger" }, [
                              _vm._v("*"),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c("b-form-input", {
                      class: { "is-invalid": _vm.$v.firstName.$error },
                      attrs: {
                        id: "firstName",
                        type: "text",
                        placeholder: _vm.$t(
                          "adminComponents.inviteUserOverlay.form.firstName.placeholder"
                        ),
                      },
                      model: {
                        value: _vm.firstName,
                        callback: function ($$v) {
                          _vm.firstName = $$v
                        },
                        expression: "firstName",
                      },
                    }),
                    _vm.$v.firstName.$error
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          !_vm.$v.firstName.required
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "adminComponents.inviteUserOverlay.form.firstName.required"
                                    )
                                  )
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: { id: "last-name-group", "label-for": "lastName" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "adminComponents.inviteUserOverlay.form.lastName.label"
                                  )
                                ) +
                                " "
                            ),
                            _c("span", { staticClass: "text-danger" }, [
                              _vm._v("*"),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c("b-form-input", {
                      class: { "is-invalid": _vm.$v.lastName.$error },
                      attrs: {
                        id: "lastName",
                        type: "text",
                        placeholder: _vm.$t(
                          "adminComponents.inviteUserOverlay.form.lastName.placeholder"
                        ),
                      },
                      model: {
                        value: _vm.lastName,
                        callback: function ($$v) {
                          _vm.lastName = $$v
                        },
                        expression: "lastName",
                      },
                    }),
                    _vm.$v.lastName.$error
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          !_vm.$v.lastName.required
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "adminComponents.inviteUserOverlay.form.lastName.required"
                                    )
                                  )
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "b-form-checkbox",
            {
              staticClass: "mt-2",
              attrs: { name: "check-button", switch: "" },
              model: {
                value: _vm.enableAdminAccess,
                callback: function ($$v) {
                  _vm.enableAdminAccess = $$v
                },
                expression: "enableAdminAccess",
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("adminComponents.inviteUserOverlay.form.adminAccess")
                  ) +
                  " "
              ),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "row mt-5" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c(
              "b-button",
              { attrs: { variant: "primary" }, on: { click: _vm.clickInvite } },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("adminComponents.inviteUserOverlay.inviteButton")
                    ) +
                    " "
                ),
              ]
            ),
            _c(
              "b-button",
              {
                staticClass: "ml-2",
                attrs: { variant: "light" },
                on: { click: _vm.clickClose },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("adminComponents.inviteUserOverlay.cancelButton")
                    ) +
                    " "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }