<script>
import OrganizationWalletCard from "./organization-wallet-card.vue"
import OrganizationTransactionCard from "./organization-transaction-card.vue"
import { EventBus } from "@/shared/util/event-bus.js";

export default {
  props: {
    organization: Object
  },
  components: {
    OrganizationWalletCard,
    OrganizationTransactionCard
  },
  data() {
    return {
      /***** Data variables *****/
      numberOfComponentsShouldGetLoaded: 2,
      numberOfComponentsGetLoaded: 0,
      /****** Operational variable ******/
      isComponentLoading: true,
      isFetchingDataError: false,
    }
  },
  created() {
    EventBus.$on("creditAndTransactionComponetGetLoaded", async () => {
      this.numberOfComponentsGetLoaded += 1

      if(this.numberOfComponentsShouldGetLoaded === this.numberOfComponentsGetLoaded) {
        this.isComponentLoading = false;
      }
    });
  },
  method: {
  }
}
</script>

<template>
  <div>

    <!-- Component Loading -->
    <div
      class="row pt-5"
      v-if="isComponentLoading"
    >
      <div class="col-md-12 text-center p-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>

    <!-- Display Data -->
    <div v-show="!isComponentLoading && !isFetchingDataError" class="row mt-3 animate__animated animate__fadeInUp animate__slow">
      <div class="col-md-3">
        <OrganizationWalletCard :wallet="organization.wallet" />
      </div>

      <div class="col-md-9">
        <OrganizationTransactionCard :organization="organization" />
      </div>
    </div>
  </div>
</template>