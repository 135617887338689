<script>
import { Cropper } from "vue-advanced-cropper";

export default {
  props: {
    visible: Boolean,
    imageFile: File
  },
  components: {
    Cropper
  },
  data() {
    return {
      imageCanvas: null,
      croppedImage: null,
      croppedCanvas: null
    };
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  mounted() {
    const cropperFileReader = new FileReader();
    cropperFileReader.readAsDataURL(this.imageFile);
    cropperFileReader.onload = e => {
      this.$refs.cropper.src = e.target.result;
    };
  },
  methods: {
    cropperChange({ canvas }) {
      this.croppedCanvas = canvas;
    },
    applyCropImage() {
      this.$emit("apply", this.croppedCanvas);
    },
    cancel() {
      this.$emit("close");
    }
  }
};
</script>

<template>
  <!-- Avatar Cropper Modal -->
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
  >
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-12">
            <h5>
              Foto zuschneiden
            </h5>
          </div>
          <div class="col-lg-12 mt-5">
            <!-- Company image -->
            <div>
              <cropper
                ref="cropper"
                :stencil-props="{
                  aspectRatio: 1
                }"
                classname="cropper"
                @change="cropperChange"
              ></cropper>
            </div>
          </div>

          <div class="col-md-12 mt-5">
            <b-button variant="primary" @click="applyCropImage" class="mr-2"
              >Zuschneiden</b-button
            >
            <b-button variant="light" @click="showModal = false"
              >Abbrechen</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
