var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", { staticClass: "footer" }, [
    _c("div", { staticClass: "container-fluid" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-8" }, [
          _vm._v(" " + _vm._s(new Date().getFullYear()) + " © Redem GmbH | "),
          _c(
            "a",
            {
              attrs: {
                href: "https://redem.io/terms-and-conditions/",
                target: "_blank",
              },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("general.footer.termsAndCondition")) + " "
              ),
            ]
          ),
          _vm._v(" | "),
          _c(
            "a",
            {
              attrs: {
                href: "https://redem.io/privacy-policy/",
                target: "_blank",
              },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("general.footer.dataPrivacyPolicy")) + " "
              ),
            ]
          ),
        ]),
        _c("div", { staticClass: "col-sm-4" }, [
          _c("div", { staticClass: "text-sm-right d-none d-sm-block" }, [
            _c("p", [_vm._v("V" + _vm._s(this.$store.state.appVersion))]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }