<script>
import EditUserOverlay from '../overlays/edit-user-overlay.vue'
import AccessLevels from "@/shared/enums/aceessLevels";

export default {
  props: {
    user: Object,
    organizationOwner: Object,
    loggedInUser: Object
  },
  components: {
    EditUserOverlay
  },
  data() {
    return {
      /***** Data variables *****/
      accessLevels: AccessLevels,
      /****** Operational variable ******/
      isDisplayEditUserOverlay: false
    }
  },
  methods: {
    clickEditUser() {
      this.isDisplayEditUserOverlay = true
    }
  }
}
</script>

<template>
  <div class="card" style="height: 185px">
    <div class="card-body">
      <div class="card-text">
        <div class="row align-items-center justify-content-between">
          <div class="col-md-2">
            <img
              v-if="user && user.image"
              :src="user.image"
              class="rounded-circle"
              style="height: 60px"
            />
            <img
              v-else
              src="https://redem-resources.s3.eu-central-1.amazonaws.com/default-images/default-avatar.png"
              class="rounded-circle"
              style="height: 60px"
            />
          </div>
          <div class="col-md-10">
            <div style="font-size: medium; font-weight: 600"> {{ this.user.name.first }} {{ this.user.name.last }} </div>
            <div class="mt-1"> {{ this.user.email }} </div>
            <div class="mt-1">
              <b-badge class="mr-2" style="font-size: 12px" variant="primary" v-if="this.organizationOwner.id === this.user.id">
                {{ $t("adminComponents.manageUsersWidget.activeUserCard.organizationOwner")}}
              </b-badge>
              <b-badge style="font-size: 12px" variant="success" v-if="this.user.accessLevel === accessLevels.ADMIN">
                {{ this.user.accessLevel }}
              </b-badge>
            </div>
          </div>
        </div>

        <hr/>

        <div class="row">
          <div class="col-md-10">
            <div style="font-size: medium; font-weight: 600;">{{$t("adminComponents.manageUsersWidget.activeUserCard.dataImports")}}: {{this.user.numberOfImports}}</div>
            <!-- <div class="mt-1"> last logged in: 25/05/2023 </div> -->
          </div>
          <div class="col-md-2 text-right" v-if="loggedInUser.id !== this.user.id">
            <b-button variant="light" @click="clickEditUser">
              <i class='bx bx-pencil' style="font-size: large;"></i>
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit User Overlay -->
    <EditUserOverlay
      v-if="isDisplayEditUserOverlay === true"
      :visible="isDisplayEditUserOverlay"
      @close="isDisplayEditUserOverlay = false"
      :user="user"
      :organizationOwner="organizationOwner"
    />
  </div>
</template>

