var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        centered: "",
        "hide-footer": "",
        "hide-header": "",
        "no-close-on-backdrop": "",
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-12" }, [
              _c("h5", [_vm._v(" Foto zuschneiden ")]),
            ]),
            _c("div", { staticClass: "col-lg-12 mt-5" }, [
              _c(
                "div",
                [
                  _c("cropper", {
                    ref: "cropper",
                    attrs: {
                      "stencil-props": {
                        aspectRatio: 1,
                      },
                      classname: "cropper",
                    },
                    on: { change: _vm.cropperChange },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-md-12 mt-5" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "mr-2",
                    attrs: { variant: "primary" },
                    on: { click: _vm.applyCropImage },
                  },
                  [_vm._v("Zuschneiden")]
                ),
                _c(
                  "b-button",
                  {
                    attrs: { variant: "light" },
                    on: {
                      click: function ($event) {
                        _vm.showModal = false
                      },
                    },
                  },
                  [_vm._v("Abbrechen")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }