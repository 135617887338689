import { encrypt } from "@/shared/crypto/crypto-helpers";
import { ApiCall } from "../apiBase.js";
import CustomErrorMessage from "@/shared/util/customErrorMessage.js";
class AuthService {
  async getLoginToken(email, password, companyId) {
    const encryptedPassword = await encrypt(Buffer.from(password));
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation login($email: String!, $pw: EncryptedPassword!, $companyId: ID){
            login(email: $email, password: $pw, companyId: $companyId) {
              accessToken,
              accessLevel,
              expiresIn
            }
          }
        `,
        variables: {
          email,
          pw: encryptedPassword,
          companyId
        },
        operationName: "login"
      }
    });
    if (response?.data?.data?.login) {
      return response.data.data.login;
    }
    return response.data.errors[0];
  }

  async getLoggedInUser() {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          query me {
            me {
              id
              name {
                first
                last
              }
              email
              image
              company {
                id
                name
                image
                address {
                  street
                  city
                  postalCode
                  country
                }
                isActivated
                contractStatus
              }
              accessLevel
              magicKey
              skipIngressIntegrationsPrerequisite
              watchGetStarted
              userPreferences {
                id
                nipoIntegration {
                  username
                  password
                  region
                  domain
                }
                isDatafileUploading
                isDatafileUpdating
              }
            }
          }
        `,
        operationName: "me",
        withCredentials: true
      }
    });

    if (response?.data?.data?.me && !response?.data?.errors) {
      return response?.data?.data?.me;
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      );
    }
  }

  async sendPasswordResetEmail(email) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation sendPasswordResetEmail($email: String!) {
            sendPasswordResetEmail(email: $email) {
              message
              status
            }
          }
        `,
        variables: {
          email
        },
        operationName: "sendPasswordResetEmail"
      }
    });
    
    if (response?.data?.data?.sendPasswordResetEmail && !response?.data?.errors) {
      return response?.data?.data?.sendPasswordResetEmail;
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      );
    }
  }

  async setNewPassword(resetToken, newPassword) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation resetPassword($newPassword: String!, $resetToken: String!) {
            resetPassword(newPassword: $newPassword, passwordResetToken: $resetToken) {
              message
              status
            }
          }
        `,
        variables: {
          newPassword,
          resetToken
        },
        operationName: "resetPassword"
      }
    });
    if (response?.data?.data?.resetPassword) {
      return response.data.data.resetPassword;
    }
    return response.data.errors[0];
  }

  async changePassword(oldPassword, newPassword) {
    const response = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation changePassword($oldPassword: EncryptedPassword!, $newPassword: String!) {
            changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
              message
              status
            }
          }
        `,
        variables: {
          oldPassword,
          newPassword
        },
        operationName: "changePassword"
      }
    });

    if (response?.data?.data?.changePassword && !response?.data?.errors) {
      return response?.data?.data?.changePassword;
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      );
    }
  }

  async registerCompany(input) {
      let response = await ApiCall({
        method: "post",
        data: {
          query: `
            mutation registerCompany($input: RegisterInput!) {
              registerCompany(input: $input) {
                status
                message
              }
            }
          `,
          variables: {
            input
          },
          operationName: "registerCompany"
        }
      });

      if (
        response?.data?.data?.registerCompany &&
        !response?.data?.errors
      ) {
        const {status, message} = response.data.data.registerCompany;
        return {status, message};
      } else {
        throw new CustomErrorMessage(
          response?.data?.errors[0]?.extensions?.code,
          response?.data?.errors[0].message
        );
      }

  }
}

export default new AuthService();
