import { ApiCall } from "../apiBase.js";
import CustomErrorMessage from "@/shared/util/customErrorMessage.js";

class UserService {
  /********* invite User for Specific Company *********/
  async inviteUserForSpecificCompany(userDataObject, companyId) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation inviteUserForSpecificCompany($newUser: InviteUserInput!, $companyId: ID!) {
            inviteUserForSpecificCompany(newUser: $newUser, companyId: $companyId) {
              status
              message
            }
          }
        `,
        variables: {
          newUser: userDataObject,
          companyId: companyId
        }
      }
    });

    if (response?.data?.data?.inviteUserForSpecificCompany && !response?.data?.errors) {
      return response?.data?.data?.inviteUserForSpecificCompany;
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      );
    }
  }

  /********* Resend invitation to Pending User *********/
  async resendUserInvitation(userId) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation resendUserInvitation($userId: ID!) {
            resendUserInvitation(userId: $userId) {
              status
              message
            }
          }
        `,
        variables: {
          userId: userId
        }
      }
    });

    if (response?.data?.data?.resendUserInvitation && !response?.data?.errors) {
      return response?.data?.data?.resendUserInvitation;
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      );
    }
  }

  /********* Delete User *********/
  async deleteUser(userId) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation deleteUser($userId: ID!) {
            deleteUser(userId: $userId) {
              status
              message
            }
          }
        `,
        variables: {
          userId: userId
        }
      }
    });

    if (response?.data?.data?.deleteUser && !response?.data?.errors) {
      return response?.data?.data?.deleteUser;
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      );
    }
  }

  /********* Update User *********/
  async updateUser(updateUserObject) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation updateUser($updateuserInput: UserUpdateInput!) {
            updateUser(userToUpdate: $updateuserInput) {
              status
              message
            }
          }
        `,
        variables: {
          updateuserInput: updateUserObject
        }
      }
    });

    if (response?.data?.data?.updateUser && !response?.data?.errors) {
      return response?.data?.data?.updateUser;
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      );
    }
  }

  /********* Update Invited User *********/
  async updateUserOnActivation(updateUserObject) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation updateUserOnActivation($updateuserInput: UpdateUserOnActivationInput!) {
            updateUserOnActivation(userToUpdate: $updateuserInput) {
              status
              message
            }
          }
        `,
        variables: {
          updateuserInput: updateUserObject
        },
        operationName: "updateUserOnActivation"
      }
    });

    if (response?.data?.data?.updateUserOnActivation && !response?.data?.errors) {
      return response?.data?.data?.updateUserOnActivation;
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      );
    }
  }

  /************* get Presigned URL for User Avatart *************/
  async getUserAvatarPreSignedURL(fileName, userId) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getUserAvatarUploadURL($fileName: String!, $userId: ID!) {
            getUserAvatarUploadURL(imageKey: $fileName, userId: $userId) {
              signedUrl
            }
          }
        `,
        variables: {
          fileName: fileName,
          userId: userId
        }
      }
    });

    if (response?.data?.data?.getUserAvatarUploadURL && !response?.data?.errors) {
      return response?.data?.data?.getUserAvatarUploadURL;
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      );
    }
  }

  /************* Get User by Id *********/
  async getUserById(userId) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getUser($userId: ID!){
            getUser(userId: $userId) {
              id,
              name {
                first,
                last
              }
              image,
              email,
              telephone,
              accessLevel,
              position,
              hasAcceptedTerms,
              hasConfirmedEmail,
              createdDate,
              status,
              numberOfDatafiles
              watchGetStarted
              watchNPS
              magicKey
              skipIngressIntegrationsPrerequisite
            }
          }
        `,
        variables: {
          userId: userId
        }
      }
    });

    if (result?.data?.data?.getUser && !result?.data?.errors) {
      return result.data.data.getUser;
    } else {
      return result?.data?.errors;
    }
  }

  /************ Get Datafiles For Super Admin ********/
  async getDatafilesForLoggedInUser(importType, companyId, page, limit) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getDatafilesForLoggedInUser ($filters: DatafileFiltersInput!, $paginationInfo:PaginationInfoInput!) {
            getDatafilesForLoggedInUser (filters: $filters, paginationInfo: $paginationInfo) {
              datafiles {
                id
                name
                createdDate
                importType
                isDeleted
                company{
                  id
                  name
                  image
                }
                owner{
                  id
                  name {
                    first
                    last
                  }
                  image
                  email
                }
                integration {
                  id
                  name
                }
                isLiveCleaning
                certificateLevel
              }
              previousPage
              currentPage
              nextPage
              totalNumberOfDatafiles
            }
          }
        `,
        variables: {
          filters: {
            importType: importType,
            companyId: companyId
          },
          paginationInfo: {
            page: page,
            limit: limit
          }
        }
      }
    });

    if (response?.data?.data?.getDatafilesForLoggedInUser && !response?.data?.errors) {
      return response?.data?.data?.getDatafilesForLoggedInUser;
    } else {
      return response?.data?.errors[0];
    }
  }

  /********* Get All Users in Database *********/
  async getAllUsers() {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getAllUsers {
            getAllUsers {
              id,
              name {
                first,
                last
              }
              image,
              email,
              telephone,
              accessLevel,
              position,
              hasAcceptedTerms,
              hasConfirmedEmail,
              createdDate,
              status,
              company {
                id
                name
                address
              }
              numberOfDatafiles
              watchGetStarted
              watchNPS
              magicKey
              skipIngressIntegrationsPrerequisite
            }
          }
        `
      }
    });

    if (result?.data?.data?.getAllUsers && !result?.data?.errors) {
      return result.data.data.getAllUsers;
    } else {
      return result?.data?.errors;
    }
  }

  /******** Get Datafile Summary *********/
  async getDatafilesSummary() {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getMyDatafilesSummaryForDashboard {
            getMyDatafilesSummaryForDashboard {
              numberOfDatafiles
              numberOfQualityScores
              numberOfDataRecords
            }
          }
        `
      }
    });

    if (
      response?.data?.data?.getMyDatafilesSummaryForDashboard &&
      !response?.data?.errors
    ) {
      return response?.data?.data?.getMyDatafilesSummaryForDashboard;
    } else {
      return response?.data?.errors[0];
    }
  }

  /********* Update User *********/
  async updateGetStarted(userId) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation updateGetStarted($userId: ID!) {
            updateGetStarted(userId: $userId) {
              status
              message
            }
          }
        `,
        variables: {
          userId
        }
      }
    });

    if (result?.data?.data?.updateGetStarted && !result?.data?.errors) {
      return result.data.data.updateGetStarted.status;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }

  /********* Update User *********/
  async updateNPS(userId, nextNPSDate) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation updateNPS($userId: ID!, $nextNPSDate: String) {
            updateNPS(userId: $userId, nextNPSDate: $nextNPSDate) {
              status
              message
            }
          }
        `,
        variables: {
          userId,
          nextNPSDate
        }
      }
    });

    if (result?.data?.data?.updateNPS && !result?.data?.errors) {
      return result.data.data.updateNPS.status;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }

  /********* Skip Ingress Integrations Prerequisite *********/
  async skipIngressIntegrationsPrerequisite(userId) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation skipIngressIntegrationsPrerequisite($userId: ID!) {
            skipIngressIntegrationsPrerequisite(userId: $userId) {
              status
              message
            }
          }
        `,
        variables: {
          userId
        }
      }
    });

    if (result?.data?.data?.skipIngressIntegrationsPrerequisite && !result?.data?.errors) {
      return result.data.data.skipIngressIntegrationsPrerequisite.status;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }

  /********* Set User Preferences For Nipo Integration *********/
  async setUserPreferencesForNipoIntegration(preferences) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation updateUserPreferenceForNipoIntegration($preferencesInput: NipoIntegrationPreferenesInput!) {
            updateUserPreferenceForNipoIntegration(preferences: $preferencesInput) {
              status
              message
            }
          }
        `,
        variables: {
          preferencesInput: preferences
        }
      }
    });

    if (result?.data?.data?.updateUserPreferenceForNipoIntegration && !result?.data?.errors) {
      return result.data.data.updateUserPreferenceForNipoIntegration.status;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }

  /********* Change status of datafile upload progress in user preferences *********/
  async changeDatafileUploadingProgressStatus({ datafileInputObject, selectedColumns, creditAmount, status }) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation changeDatafileUploadingProgressStatus($datafileInputObject: String, $selectedColumns: String, $creditAmount: String, $status: Boolean) {
            changeDatafileUploadingProgressStatus(datafileInputObject: $datafileInputObject, selectedColumns: $selectedColumns, creditAmount: $creditAmount, status: $status) {
              status
              message
            }
          }
        `,
        variables: {
          datafileInputObject,
          selectedColumns,
          creditAmount,
          status
        }
      }
    });

    if (result?.data?.data?.changeDatafileUploadingProgressStatus && !result?.data?.errors) {
      return result.data.data.changeDatafileUploadingProgressStatus.status;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }

  async getClearLocalStorage() {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getClearLocalStorage {
            getClearLocalStorage
          }
        `
      }
    });
    const clearLocalStorage = result?.data?.data?.getClearLocalStorage;
    if ((clearLocalStorage || clearLocalStorage === false) && !result?.data?.errors) {
      return result.data.data.getClearLocalStorage;
    } else {
      return result?.data?.errors;
    }
  }

  async setClearLocalStorage(value) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation setClearLocalStorage($value: Boolean!) {
            setClearLocalStorage(value: $value) {
              status
            }
          }
        `,
        variables: {
          value
        }
      }
    });
    if (result?.data?.data?.setClearLocalStorage && !result?.data?.errors) {
      return result.data.data.setClearLocalStorage.status;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }

  async setSendNotification(value, type) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation setSendNotification($value: Boolean!, $type: String!) {
            setSendNotification(value: $value, type: $type) {
              status
            }
          }
        `,
        variables: {
          value,
          type
        }
      }
    });
    if (result?.data?.data?.setSendNotification && !result?.data?.errors) {
      return result.data.data.setSendNotification.status;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }
}

export default new UserService();
