var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        centered: "",
        "hide-footer": "",
        "hide-header": "",
        "no-close-on-backdrop": "",
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("h5", { staticClass: "mb-4" }, [
            _vm._v(_vm._s(_vm.$t("general.expireAlert.title"))),
          ]),
          _c("span", [
            _vm._v(
              " " +
                _vm._s(_vm.$t("general.expireAlert.description.line1")) +
                " "
            ),
            _c("br"),
            _vm._v(
              " " +
                _vm._s(_vm.$t("general.expireAlert.description.line2")) +
                " "
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "col-md-12 mt-4" },
          [
            _c(
              "b-button",
              {
                attrs: { variant: "success" },
                on: { click: _vm.updateConsent },
              },
              [_vm._v("Noted")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }