var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isDisplay
        ? _c("vertical", [_vm._t("default")], 2)
        : _c("div", { staticClass: "text-center mt-5" }, [
            _c("img", {
              staticClass: "img-fluid mb-4 mt-3",
              staticStyle: { width: "350px" },
              attrs: {
                src: require("@/assets/images/onlyMobile.png"),
                alt: "",
              },
            }),
            _c("h3", [_vm._v("Opps!")]),
            _c("h5", [_vm._v(_vm._s(_vm.$t("general.onlyForDesktop")))]),
          ]),
      _vm.isDisplayGetStartedModal === true
        ? _c("GetStartedModal", {
            attrs: {
              visible: _vm.isDisplayGetStartedModal,
              user: this.loggedInUser,
            },
            on: {
              close: function ($event) {
                _vm.isDisplayGetStartedModal = false
              },
            },
          })
        : _vm._e(),
      _vm.isDisplayNPSModal === true
        ? _c("NPSModal", {
            attrs: { visible: _vm.isDisplayNPSModal, user: this.loggedInUser },
            on: {
              close: function ($event) {
                _vm.isDisplayNPSModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }