var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        centered: "",
        "hide-footer": "",
        "hide-header": "",
        "no-close-on-backdrop": "",
        size: "md",
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-10" }, [
          _c(
            "div",
            { staticStyle: { "font-size": "medium", "font-weight": "600" } },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("adminComponents.changePasswordOverlay.title")
                  ) +
                  " "
              ),
            ]
          ),
          _c("div", { staticClass: "mt-2" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("adminComponents.changePasswordOverlay.description")
                )
            ),
          ]),
        ]),
        _c("div", { staticClass: "col-md-2 text-right" }, [
          _c("i", {
            staticClass: "bx bx-x",
            staticStyle: { "font-size": "x-large", cursor: "pointer" },
            on: { click: _vm.clickClose },
          }),
        ]),
        _c("div", { staticClass: "col-md-12" }, [_c("hr")]),
      ]),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c(
            "b-alert",
            {
              staticClass: "mt-3 mb-3",
              attrs: { variant: "danger", dismissible: "" },
              model: {
                value: _vm.isChangePasswordError,
                callback: function ($$v) {
                  _vm.isChangePasswordError = $$v
                },
                expression: "isChangePasswordError",
              },
            },
            [_vm._v(_vm._s(_vm.changePasswordErrorMessage))]
          ),
          _c(
            "b-alert",
            {
              staticClass: "mt-3 mb-3",
              attrs: { variant: "success" },
              model: {
                value: _vm.isChangePasswordSuccess,
                callback: function ($$v) {
                  _vm.isChangePasswordSuccess = $$v
                },
                expression: "isChangePasswordSuccess",
              },
            },
            [
              _c("i", { staticClass: "bx bx-check-double bx-tada mr-1" }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "adminComponents.changePasswordOverlay.successMessage"
                    )
                  ) +
                  " "
              ),
            ]
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                id: "current-password-group",
                "label-for": "currentPassword",
              },
              scopedSlots: _vm._u([
                {
                  key: "label",
                  fn: function () {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "adminComponents.changePasswordOverlay.form.currentPassword.label"
                            )
                          ) +
                          " "
                      ),
                      _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("b-form-input", {
                class: { "is-invalid": _vm.$v.currentPassword.$error },
                attrs: {
                  id: "currentPassword",
                  type: "password",
                  placeholder: this.$i18n.t(
                    "adminComponents.changePasswordOverlay.form.currentPassword.placeholder"
                  ),
                },
                model: {
                  value: _vm.currentPassword,
                  callback: function ($$v) {
                    _vm.currentPassword = $$v
                  },
                  expression: "currentPassword",
                },
              }),
              _vm.$v.currentPassword.$error
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    !_vm.$v.currentPassword.required
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "adminComponents.changePasswordOverlay.form.currentPassword.required"
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: { id: "new-password-group", "label-for": "newPassword" },
              scopedSlots: _vm._u([
                {
                  key: "label",
                  fn: function () {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "adminComponents.changePasswordOverlay.form.newPassword.label"
                            )
                          ) +
                          " "
                      ),
                      _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("b-form-input", {
                class: { "is-invalid": _vm.$v.newPassword.$error },
                attrs: {
                  id: "newPassword",
                  type: "password",
                  placeholder: this.$i18n.t(
                    "adminComponents.changePasswordOverlay.form.newPassword.placeholder"
                  ),
                },
                model: {
                  value: _vm.newPassword,
                  callback: function ($$v) {
                    _vm.newPassword = $$v
                  },
                  expression: "newPassword",
                },
              }),
              _vm.$v.newPassword.$error
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    !_vm.$v.newPassword.required
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "adminComponents.changePasswordOverlay.form.newPassword.required"
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    !_vm.$v.confirmPassword.minLength
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "adminComponents.changePasswordOverlay.form.newPassword.minLength"
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                id: "confirm-password-group",
                "label-for": "confirmPassword",
              },
              scopedSlots: _vm._u([
                {
                  key: "label",
                  fn: function () {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "adminComponents.changePasswordOverlay.form.confirmPassword.label"
                            )
                          ) +
                          " "
                      ),
                      _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("b-form-input", {
                class: { "is-invalid": _vm.$v.confirmPassword.$error },
                attrs: {
                  id: "confirmPassword",
                  type: "password",
                  placeholder: this.$i18n.t(
                    "adminComponents.changePasswordOverlay.form.confirmPassword.placeholder"
                  ),
                },
                model: {
                  value: _vm.confirmPassword,
                  callback: function ($$v) {
                    _vm.confirmPassword = $$v
                  },
                  expression: "confirmPassword",
                },
              }),
              _vm.$v.confirmPassword.$error
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    !_vm.$v.confirmPassword.required
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "adminComponents.changePasswordOverlay.form.confirmPassword.required"
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    !_vm.$v.confirmPassword.sameAsPassword
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "adminComponents.changePasswordOverlay.form.confirmPassword.notMatch"
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "row mt-5" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c(
              "b-button",
              {
                attrs: { variant: "primary" },
                on: { click: _vm.clickChangePassword },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "adminComponents.changePasswordOverlay.changePasswordButton"
                      )
                    ) +
                    " "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }