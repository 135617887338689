<script>
import appConfig from "@/app.config";
import Layout from "@/router/layouts/main";
import PageHeader from "@/shared/components/page-header";
import OrganizationDetailsHeader from '@/shared/components/administrational-components/widgets/organization-details-header.vue';
import UserManagement from '@/shared/components/administrational-components/widgets/user-management.vue';
import CreditAndTransactionManagement from '@/shared/components/administrational-components/widgets/credit-and-transaction-managment.vue';
import { getErrorMessageByErrorCode } from "@/shared/util/helpers.js";
import AuthService from "@/api/services/auth.service.js";
import CompanyService from "@/api/services/company.service.js";

/**
 * Administration
 */
export default {
  page: {
    title: "Organization Administration",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader,
    OrganizationDetailsHeader,
    UserManagement,
    CreditAndTransactionManagement
  },
  data() {
    return {
      /***** Data variables *****/
      loggedInUser: null,
      organization: null,
      organizationAdmins: null,
      organizationStats: null,
      /****** Operational variable ******/
      isPageLoading: false,
      isFetchingDataError: false,
      dataFetchingnErrorMessage: null
    };
  },
  computed: {
    computedItems() {
      var items = [
        {
          text: "Dashboard",
          href: "/"
        },
        {
          text: "Administration",
          active: true
        }
      ];
      return items;
    }
  },
  async mounted() {
    this.isPageLoading = true;
    await this.getLoggedInUser();
    await this.getOrganization();
    await this.getOrganizationAdmins();
    await this.getOrganizationStats();
    this.isPageLoading = false;
  },
  methods: {
    async getLoggedInUser() {
      try {
        this.loggedInUser = await AuthService.getLoggedInUser();
      } catch (error) {
        this.isFetchingDataError = true;
        this.dataFetchingnErrorMessage = await getErrorMessageByErrorCode(error.code);
      }
    },
    async getOrganization() {
      try {
        this.organization = await CompanyService.getCompanyById(this.loggedInUser.company.id);
      } catch (error) {
        this.isFetchingDataError = true;
        this.dataFetchingnErrorMessage = await getErrorMessageByErrorCode(error.code);
      }
    },
    async getOrganizationAdmins() {
      try {
        this.organizationAdmins = await CompanyService.getActiveCompanyAdminUsers(this.loggedInUser.company.id);
      } catch (error) {
        this.isFetchingDataError = true;
        this.dataFetchingnErrorMessage = await getErrorMessageByErrorCode(error.code);
      }
    },
    async getOrganizationStats() {
      try {
        this.organizationStats = await CompanyService.getCompanyStats(this.loggedInUser.company.id);
      } catch (error) {
        this.isFetchingDataError = true;
        this.dataFetchingnErrorMessage = await getErrorMessageByErrorCode(error.code);
      }
    }
  }
};
</script>

<template>
  <Layout>
    <!-- Page Loading -->
    <div
      class="row pt-5"
      v-if="isPageLoading"
    >
      <div class="col-md-12 text-center p-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>

    <!-- Display Organization Details -->
    <div v-if="!isPageLoading && organization && !isFetchingDataError">
      <PageHeader title="Administration" :items="computedItems" />

      <OrganizationDetailsHeader
        :organization="organization"
        :organizationAdmins="organizationAdmins"
        :organizationStats="organizationStats"
        class="mb-4"
      />

      <b-tabs content-class="p-2">
        <!-- Credit & Transaction Management -->
        <b-tab lazy>
          <template v-slot:title>
            <span class="d-none d-sm-inline-block">
              <i class='bx bx-credit-card-alt' style="font-size: medium;"></i> {{$t("adminComponents.tabTitles.creditAndTransactionManagement")}}
            </span>
          </template>
          <CreditAndTransactionManagement :organization="organization" />
        </b-tab>

        <!-- User Management -->
        <b-tab lazy>
          <template v-slot:title>
            <span class="d-none d-sm-inline-block">
              <i class='bx bx-group' style="font-size: medium;"></i> {{$t("adminComponents.tabTitles.userManagement")}}
            </span>
          </template>
          <UserManagement :organization="organization" :loggedInUser="loggedInUser" />
        </b-tab>
      </b-tabs>
    </div>

    <!-- Display Error -->
    <div class="row pt-5" v-if="!isPageLoading && isFetchingDataError">
      <div class="col-md-12 text-center">
        {{ this.dataFetchingnErrorMessage }}
      </div>
    </div>

  </Layout>
</template>
