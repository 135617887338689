import { render, staticRenderFns } from "./new-organizations.vue?vue&type=template&id=d3a9c31c&"
import script from "./new-organizations.vue?vue&type=script&lang=js&"
export * from "./new-organizations.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\mitte\\Redem\\redem-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d3a9c31c')) {
      api.createRecord('d3a9c31c', component.options)
    } else {
      api.reload('d3a9c31c', component.options)
    }
    module.hot.accept("./new-organizations.vue?vue&type=template&id=d3a9c31c&", function () {
      api.rerender('d3a9c31c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/redem/admin-panel/components/widgets/new-organizations.vue"
export default component.exports