export const ALGORITHM_TYPES = {
  IS: "IS",
  PS: "PS",
  SDS: "SDS",
  R: "R",
  TS: "TS",
  IBS: "IBS",
  CS: "CS",
  OES: "OES"
};

export const DATA_CLEANING_METHODS = {
  SCORE_THRESHOLD: "SCORE_THRESHOLD",
  WEIGHTING: "WEIGHTING"
};

export const ANALYSIS_TAB_OPTIONS = {
  UNCLEANED: 0,
  CLEANED: 1
};

export const INVALID_SCORE = -999;
export const EMPTY_CELL = "-999";

export const TRAFFIC_LIGHT_RANGES = {
  GREEN_START: 80,
  GREEN_END: 100,
  LIGHT_GREEN_START: 60,
  LIGHT_GREEN_END: 79,
  YELLOW_START: 40,
  YELLOW_END: 59,
  RED_START: 0,
  RED_END: 39
};

export const CREDIT_RANGES = {
  GREEN_START: 20,
  GREEN_END: 100,
  YELLOW_START: 10,
  YELLOW_END: 19,
  RED_START: 0,
  RED_END: 9
};

export const LIGHT_GREEN = "#a5e79a";
export const OES_QUESTION_TITLE_CHAR_LIMIT = 250;
export const MAX_OES_QUESTIONS =10;

export const WEBSOCKET_UPDATE_INTERVAL = 500;
export const MAX_REQUEST_TIME = 2000;
export const BACKEND_HEALTH_CHECK_INTERVAL = 3000;
