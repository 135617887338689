import "@/design/index.scss";
import store from "@/state/store";
import AnimateCSS from "animate.css";
import BootstrapVue from "bootstrap-vue";
import 'regenerator-runtime/runtime';
import vco from "v-click-outside";
import VueMask from "v-mask";
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import VueCookies from "vue-cookies";
import Gravatar from "vue-gravatar";
import VueGtm from "vue-gtm";
import VueHtml2Canvas from "vue-html2canvas";
import VueRouter from "vue-router";
import VueSweetalert2 from "vue-sweetalert2";
import Scrollspy from "vue2-scrollspy";
import Vuelidate from "vuelidate";
import router from "./router/index";

import App from "./App.vue";

import i18n from "./i18n";

Vue.use(AnimateCSS);
Vue.use(VueRouter);
Vue.use(vco);
Vue.use(Scrollspy);
const VueScrollTo = require("vue-scrollto");
Vue.use(VueScrollTo);
Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(VueMask);
Vue.use(require("vue-chartist"));
Vue.use(VueSweetalert2);
Vue.use(VueCookies);
Vue.component("apexchart", VueApexCharts);
Vue.component("v-gravatar", Gravatar);
Vue.use(VueHtml2Canvas);
Vue.use(VueGtm, {
  id: "GTM-N75LN67",
  defer: false,
  enabled: true,
  debug: false,
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount("#app");
