<script>
import { getCreditsAccoringTolanguage } from "@/shared/util/helpers.js";
import { CreditBalanceChart } from "../charts.js"
import { EventBus } from "@/shared/util/event-bus.js";

export default {
  props: {
    wallet: Object
  },
  data() {
    return {
      creditBalanceChartOptions: null,
      creditBalancePercentage: [0]
    }
  },
  async created() {
    this.creditBalanceChartOptions = new CreditBalanceChart()
    await this.calculateValuesForChart();
    EventBus.$emit("creditAndTransactionComponetGetLoaded");
  },
  methods: {
    calculateValuesForChart() {
      this.creditBalancePercentage = []
      this.creditBalancePercentage.push((this.wallet.balance / this.wallet.received) * 100);
    },
    formatNumbers(numberToFormat) {
      const langaugeToFormat = window.localStorage.getItem("locale");
      return getCreditsAccoringTolanguage(numberToFormat, langaugeToFormat);
    },
  }
}
</script>

<template>
  <div class="card" style="height: 610px">
    <div class="card-body">
      <div class="card-text">
        
        <div class="row">
          <div class="col-12">
            <span style="font-size: medium; font-weight: 600"> {{$t("adminComponents.creditWidget.title")}} </span>
          </div>
        </div>


        <!-- Wallet Empty -->
        <div class="row mt-5" v-if="this.creditBalancePercentage && !this.creditBalancePercentage[0]">
          <div class="col-12 text-center">
            <img src="@/assets/images/empty-wallet.png" class="img-fluid mb-4 mt-3" style="width: 60px" />
            <h6>
              {{$t("adminComponents.creditWidget.emptyWallet.title")}}
            </h6>
            <p>
              {{$t("adminComponents.creditWidget.emptyWallet.text")}}
            </p>
          </div>
        </div>

        <!-- Wallet Chart -->
        <div class="row mt-5" v-if="this.creditBalancePercentage && this.creditBalancePercentage[0]">
          <div class="col-12 text-center">
            <apexchart
              ref="CreditBalanceChart"
              class="apex-charts apexChartData"
              type="radialBar"
              height="300"
              dir="ltr"
              :series="this.creditBalancePercentage"
              :options="creditBalanceChartOptions.chartOptions"
            ></apexchart>
          </div>
        </div>

        <!-- Wallet Data -->
        <div class="row mt-5 mb-5" v-if="this.creditBalancePercentage && this.creditBalancePercentage[0]">
          <div class="col-6 text-center">
            {{$t("adminComponents.creditWidget.balace")}}
            <div style="font-size: medium; font-weight: 600"> {{ formatNumbers(wallet.balance) }} </div>
          </div>
          <div class="col-6 text-center">
            {{$t("adminComponents.creditWidget.receive")}}
            <div style="font-size: medium; font-weight: 600"> {{ formatNumbers(wallet.received) }} </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>