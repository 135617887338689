import crypto from "crypto";
import CryptoJS from "crypto-js";
const algorithm = 'aes-256-ctr';
const secretKey = process.env.VUE_APP_S3_DATAFILE_ENCRYPTION_KEY;
const reportPWSecret = process.env.VUE_APP_REPORT_PASSWORD_SECRET_KEY;

const encryptReportPassword = (password) => {
    let encrypted = CryptoJS.AES.encrypt(password, reportPWSecret).toString();
    return encrypted;
  };

  const decryptReportPassword = (encryptedPassword) => {
    let decrypt = CryptoJS.AES.decrypt(encryptedPassword, reportPWSecret);
    let decryptedPassword = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedPassword;
  };

const encrypt = (text) => {
    const iv = crypto.randomBytes(16);
    const cipher = crypto.createCipheriv(algorithm, secretKey, iv);
    const encrypted = Buffer.concat([cipher.update(text), cipher.final()]);
    return {
        iv: iv.toString('hex'),
        content: encrypted
    };
};

const decrypt = (hash) => {
    const decipher = crypto.createDecipheriv(algorithm, secretKey, Buffer.from(hash.iv, 'hex'));
    const decrypted = Buffer.concat([decipher.update(Buffer.from(hash.content.data)), decipher.final()]);

    return decrypted;
};

export {
    encrypt,
    decrypt,
    encryptReportPassword,
    decryptReportPassword
};
