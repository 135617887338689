<script>
import { email, required } from "vuelidate/lib/validators";
import { getErrorMessageByErrorCode } from "@/shared/util/helpers.js";
import ResponseStatus from "@/shared/enums/responseStatus";
import UserService from "@/api/services/user.service.js";
import { EventBus } from "@/shared/util/event-bus.js";

export default {
  props: {
    visible: Boolean,
    closeable: Boolean,
    organization: Object
  },
  data() {
    return {
      /******** Data Variables ******/
      email: null,
      firstName: null,
      lastName: null,
      enableAdminAccess: false,
      /******** Operational Variables *******/
      isInviteUserSuccess: false,
      isInviteUserError: false,
      inviteUserErrorMessage: null
    };
  },
  validations: {
    email: { required, email },
    firstName: { required },
    lastName: { required }
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  methods: {
    clickClose() {
      this.$emit("close");
    },
    clickInvite() {
      // stop here if form is invalid
      this.$v.$touch();

      if(this.$v.$invalid) {
        this.isUpdateOrganizationError = true;
        this.updateOrganizationErrorMessage = "Validation Error";
      } else {
        this.inviteUser();
      }
    },
    async inviteUser() {
      const inviteUserObject = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        enableAdminAccess: this.enableAdminAccess,
      }

      try {
        let response = await UserService.inviteUserForSpecificCompany(inviteUserObject, this.organization.id);
        if (response.status === ResponseStatus.SUCCESS) {
          this.isInviteUserSuccess = true;
          setTimeout(() => {
            this.isInviteUserSuccess = false;

            // reset the values
            this.resetValuesToInitialState();
            this.$emit("close");
            EventBus.$emit("refreshUserManagementTab");
          }, 2000);
        }
      } catch (error) {
        this.isInviteUserError = true;
        this.inviteUserErrorMessage = await getErrorMessageByErrorCode(error.code)
      }
    },
    resetValuesToInitialState() {
      this.email = null;
      this.firstName = null;
      this.lastName = null;
      this.enableAdminAccess = false;
    }
  }
};
</script>

<template>
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
    size="md"
  >
    <!-- Title -->
    <div class="row">
      <div class="col-md-10">
        <div style="font-size: medium; font-weight: 600"> {{$t("adminComponents.inviteUserOverlay.title")}} </div>
        <div class="mt-2"> {{$t("adminComponents.inviteUserOverlay.description")}} </div>
      </div>
      <div class="col-md-2 text-right">
        <i class='bx bx-x' style="font-size: x-large; cursor: pointer;" @click="clickClose"></i>
      </div>
      <div class="col-md-12"> <hr /> </div>
      
    </div>

    <!-- Form -->
    <div class="mt-2">
      <!-- Error Message -->
      <b-alert
        v-model="isInviteUserError"
        class="mt-3 mb-3"
        variant="danger"
        dismissible
        >{{ inviteUserErrorMessage }}</b-alert
      >

      <!-- Success Message -->
      <b-alert
        v-model="isInviteUserSuccess"
        class="mt-3 mb-3"
        variant="success"
      >
        <i class='bx bx-check-double bx-tada mr-1' ></i> {{$t("adminComponents.inviteUserOverlay.successMessage")}}
      </b-alert>

      <b-form-group id="email-group" label-for="email">
        <template v-slot:label>
          {{$t("adminComponents.inviteUserOverlay.form.email.label")}}
          <span class="text-danger">*</span>
        </template>
        <b-form-input
          id="email"
          v-model="email"
          type="email"
          :placeholder="$t('adminComponents.inviteUserOverlay.form.email.placeholder')"
          :class="{ 'is-invalid':$v.email.$error }"
        ></b-form-input>
        <div v-if="$v.email.$error" class="invalid-feedback">
          <span v-if="!$v.email.required">{{$t("adminComponents.inviteUserOverlay.form.email.required")}}</span>
          <span v-if="!$v.email.email">{{$t("adminComponents.inviteUserOverlay.form.email.invalidEmail")}}</span>
        </div>
      </b-form-group>

      <div class="row">
        <div class="col-md-6">
          <b-form-group id="first-name-group" label-for="firstName">
            <template v-slot:label>
              {{$t("adminComponents.inviteUserOverlay.form.firstName.label")}}
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              id="firstName"
              v-model="firstName"
              type="text"
              :placeholder="$t('adminComponents.inviteUserOverlay.form.firstName.placeholder')"
              :class="{ 'is-invalid':  $v.firstName.$error }"
            ></b-form-input>
            <div v-if="$v.firstName.$error" class="invalid-feedback">
              <span v-if="!$v.firstName.required">{{$t("adminComponents.inviteUserOverlay.form.firstName.required")}}</span>
            </div>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group id="last-name-group" label-for="lastName">
            <template v-slot:label>
              {{$t("adminComponents.inviteUserOverlay.form.lastName.label")}}
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              id="lastName"
              v-model="lastName"
              type="text"
              :placeholder="$t('adminComponents.inviteUserOverlay.form.lastName.placeholder')"
              :class="{ 'is-invalid': $v.lastName.$error }"
            ></b-form-input>
            <div v-if="$v.lastName.$error" class="invalid-feedback">
              <span v-if="!$v.lastName.required">{{$t("adminComponents.inviteUserOverlay.form.lastName.required")}}</span>
            </div>
          </b-form-group>
        </div>
      </div>

      <b-form-checkbox
        class="mt-2"
        v-model="enableAdminAccess"
        name="check-button"
        switch
      >
      {{$t("adminComponents.inviteUserOverlay.form.adminAccess")}}
      </b-form-checkbox>
    </div>

    <!-- Actions -->
    <div class="row mt-5">
      <div class="col-md-12">
        <b-button variant="primary" @click="clickInvite">
          {{$t("adminComponents.inviteUserOverlay.inviteButton")}}
        </b-button>
        <b-button variant="light" @click="clickClose" class="ml-2">
          {{$t("adminComponents.inviteUserOverlay.cancelButton")}}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
