var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "account-pages my-5 pt-5" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "text-center mb-5" }, [
            _vm._m(0),
            _c("h4", { staticClass: "text-uppercase" }, [
              _vm._v("Sorry, page not found"),
            ]),
            _c(
              "div",
              { staticClass: "mt-5 text-center" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { tag: "a", to: "/" },
                  },
                  [_vm._v("Back to Dashboard")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._m(1),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", { staticClass: "display-2 font-weight-medium" }, [
      _vm._v(" 4"),
      _c("i", { staticClass: "bx bx-buoy bx-spin text-primary display-3" }),
      _vm._v("4 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-md-8 col-xl-6" }, [
        _c("div", [
          _c("img", {
            staticClass: "img-fluid",
            attrs: { src: require("@/assets/images/error-img.png"), alt: "" },
          }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }