import store from "@/state/store";
import axios from "axios";
import cookie from "vue-cookies";
import { API_BASE } from "./apiConfig";

export const ApiCall = async (requestInfo, headers) => {
  if (!headers || headers === null) {
  const validToken = await store.dispatch("auth/validate");
    if (validToken !== null) {
      headers = {
        Authorization: `JWT ${validToken}`
      };
    }

    const response = axios({
      ...requestInfo,
      url: API_BASE,
      headers,
      withCredentials: true
    });
    response.catch(function(e) {
      //server down or API issue
      if (e == "Error: Request failed with status code 500") {
        cookie.remove("tokenExpireDate");
        window.localStorage.removeItem("WAITING_FOR_LOGOUT");
        window.location.href = "/login";
      }

      // unauthenticated
      if (e == "Error: Request failed with status code 401") {
        cookie.remove("tokenExpireDate");
        window.localStorage.removeItem("WAITING_FOR_LOGOUT");
        window.location.href = "/login";
      }
    });
    return response;
  }
};
