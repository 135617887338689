var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        centered: "",
        "hide-footer": "",
        "hide-header": "",
        "no-close-on-backdrop": "",
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c(
        "div",
        { staticClass: "col-lg-12 text-right mt-2" },
        [
          _c(
            "b-button",
            {
              attrs: { variant: "light" },
              on: {
                click: function ($event) {
                  return _vm.clickLater()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("general.npsModal.button.later")))]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "row text-center" }, [
        _c("div", { staticClass: "col-lg-12 mt-3" }, [
          _c("img", {
            staticClass: "img-fluid mb-4 mt-3",
            staticStyle: { width: "250px" },
            attrs: { src: require("@/assets/images/nps-image.jpg"), alt: "" },
          }),
          _c("h5", [_vm._v(_vm._s(_vm.$t("general.npsModal.title")))]),
          _c("p", [
            _vm._v(" " + _vm._s(_vm.$t("general.npsModal.descrption")) + " "),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "p-3 col-md-12 text-lg-center" },
          [
            _c(
              "b-button",
              {
                staticStyle: { width: "200px" },
                attrs: { variant: "success" },
                on: {
                  click: function ($event) {
                    return _vm.clickYes()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("general.npsModal.button.yes")))]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }