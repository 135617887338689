var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card", staticStyle: { height: "185px" } },
    [
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "card-text" }, [
          _c(
            "div",
            { staticClass: "row align-items-center justify-content-between" },
            [
              _c("div", { staticClass: "col-md-2" }, [
                _vm.user && _vm.user.image
                  ? _c("img", {
                      staticClass: "rounded-circle",
                      staticStyle: { height: "60px" },
                      attrs: { src: _vm.user.image },
                    })
                  : _c("img", {
                      staticClass: "rounded-circle",
                      staticStyle: { height: "60px" },
                      attrs: {
                        src: "https://redem-resources.s3.eu-central-1.amazonaws.com/default-images/default-avatar.png",
                      },
                    }),
              ]),
              _c("div", { staticClass: "col-md-10" }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "font-size": "medium",
                      "font-weight": "600",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(this.user.name.first) +
                        " " +
                        _vm._s(this.user.name.last) +
                        " "
                    ),
                  ]
                ),
                _c("div", { staticClass: "mt-1" }, [
                  _vm._v(" " + _vm._s(this.user.email) + " "),
                ]),
                _c(
                  "div",
                  { staticClass: "mt-1" },
                  [
                    this.organizationOwner.id === this.user.id
                      ? _c(
                          "b-badge",
                          {
                            staticClass: "mr-2",
                            staticStyle: { "font-size": "12px" },
                            attrs: { variant: "primary" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "adminComponents.manageUsersWidget.activeUserCard.organizationOwner"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    this.user.accessLevel === _vm.accessLevels.ADMIN
                      ? _c(
                          "b-badge",
                          {
                            staticStyle: { "font-size": "12px" },
                            attrs: { variant: "success" },
                          },
                          [_vm._v(" " + _vm._s(this.user.accessLevel) + " ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c("hr"),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-10" }, [
              _c(
                "div",
                {
                  staticStyle: { "font-size": "medium", "font-weight": "600" },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "adminComponents.manageUsersWidget.activeUserCard.dataImports"
                      )
                    ) +
                      ": " +
                      _vm._s(this.user.numberOfImports)
                  ),
                ]
              ),
            ]),
            _vm.loggedInUser.id !== this.user.id
              ? _c(
                  "div",
                  { staticClass: "col-md-2 text-right" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "light" },
                        on: { click: _vm.clickEditUser },
                      },
                      [
                        _c("i", {
                          staticClass: "bx bx-pencil",
                          staticStyle: { "font-size": "large" },
                        }),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm.isDisplayEditUserOverlay === true
        ? _c("EditUserOverlay", {
            attrs: {
              visible: _vm.isDisplayEditUserOverlay,
              user: _vm.user,
              organizationOwner: _vm.organizationOwner,
            },
            on: {
              close: function ($event) {
                _vm.isDisplayEditUserOverlay = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }