<script>
import router from "@/router";

import Footer from "@/shared/components/footer";
import NavBar from "@/shared/components/nav-bar";
import SideBar from "@/shared/components/side-bar";
import { EventBus } from "@/shared/util/event-bus.js";

/**
 * Vertical layout
 */
export default {
  components: { NavBar, SideBar, Footer },
  data() {
    return {
      isMenuCondensed: true,
      menuType: null
    };
  },
  created: () => {
    document.body.removeAttribute("data-layout", "horizontal");
    document.body.removeAttribute("data-topbar", "dark");
    document.body.removeAttribute("data-layout-size", "boxed");
  },
  mounted() {
    this.menuType = localStorage.getItem("navBarSetting");

    if (this.menuType && this.menuType === "collpsedNav") {
      document.body.classList.toggle("sidebar-enable");
      // eslint-disable-next-line no-unused-vars
      router.afterEach((routeTo, routeFrom) => {
        document.body.classList.remove("sidebar-enable");
        document.body.classList.remove("vertical-collpsed");
      });
      document.body.classList.toggle("vertical-collpsed");
    } else if (this.menuType && this.menuType === "expandNav") {
      // eslint-disable-next-line no-unused-vars
      router.afterEach((routeTo, routeFrom) => {
        document.body.classList.remove("sidebar-enable");
      });
      document.body.classList.remove("vertical-collpsed");
    }
  },
  methods: {
    toggleMenu() {
      EventBus.$emit("sidebar-toggle-action");

      if (document.body.classList.length > 0) {
        window.localStorage.setItem("navBarSetting", "expandNav");
      } else {
        window.localStorage.setItem("navBarSetting", "collpsedNav");
      }

      document.body.classList.toggle("sidebar-enable");
      // eslint-disable-next-line no-unused-vars
      router.afterEach((routeTo, routeFrom) => {
        document.body.classList.remove("sidebar-enable");
        document.body.classList.remove("vertical-collpsed");
      });
      document.body.classList.toggle("vertical-collpsed");


      // if (window.screen.width >= 992) {
      //   // eslint-disable-next-line no-unused-vars
      //   router.afterEach((routeTo, routeFrom) => {
      //     document.body.classList.remove("sidebar-enable");
      //     document.body.classList.remove("vertical-collpsed");
      //   });
      //   document.body.classList.toggle("vertical-collpsed");
      // } else {
      //   // eslint-disable-next-line no-unused-vars
      //   router.afterEach((routeTo, routeFrom) => {
      //     document.body.classList.remove("sidebar-enable");
      //   });
      //   document.body.classList.remove("vertical-collpsed");
      // }
      // this.isMenuCondensed = !this.isMenuCondensed;
    }
  }
};
</script>

<template>
  <div id="layout-wrapper">
    <NavBar />
    <SideBar :is-condensed="isMenuCondensed" />
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="main-content">
      <div class="page-content">
        <!-- Start Content-->
        <div class="container-fluid">
          <slot />
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>
