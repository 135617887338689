<script>
import { getErrorMessageByErrorCode } from "@/shared/util/helpers.js";
import ResponseStatus from "@/shared/enums/responseStatus";
import UserService from "@/api/services/user.service.js";
import { EventBus } from "@/shared/util/event-bus.js";

export default {
  props: {
    visible: Boolean,
    closeable: Boolean,
    user: Object
  },
  data() {
    return {
      /******** Data Variables ******/
      /******** Operational Variables *******/
      isDeleteUserSuccess: false,
      isDeleteUserError: false,
      deleteUserErrorMessage: null
    };
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  methods: {
    clickClose() {
      this.$emit("close");
    },
    async clickDelete() {
      try {
        let response = await UserService.deleteUser(this.user.id);
        if (response.status === ResponseStatus.SUCCESS) {
          this.isDeleteUserSuccess = true;
          setTimeout(() => {
            this.isDeleteUserSuccess = false;
            this.$emit("close");
            EventBus.$emit("refreshUserManagementTab");
          }, 2000);
        }
      } catch(error) {
        this.isDeleteUserError = true;
        this.deleteUserErrorMessage = await getErrorMessageByErrorCode(error.code);
      }
    },
  }
};
</script>

<template>
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
    size="md"
  >
    <!-- Title -->
    <div class="row mb-4">
      <div class="col-md-11">
        <div style="font-size: medium; font-weight: 600"> {{$t("adminComponents.deleteUserOverlay.title")}} </div>
      </div>
      <div class="col-md-1 text-center">
        <i class='bx bx-x' style="font-size: x-large; cursor: pointer;" @click="clickClose"></i>
      </div>
    </div>

    <!-- Error Message -->
    <b-alert
      v-model="isDeleteUserError"
      class="mt-3 mb-3"
      variant="danger"
      dismissible
      >{{ deleteUserErrorMessage }}</b-alert
    >

    <!-- Success Message -->
    <b-alert
      v-model="isDeleteUserSuccess"
      class="mt-3 mb-3"
      variant="success"
    >
      <i class='bx bx-check-double bx-tada mr-1' ></i> {{$t("adminComponents.deleteUserOverlay.successMessage")}}
    </b-alert>

    <!-- User -->
    <div class="row align-items-center justify-content-between">
      <div class="col-md-2">
        <img
          v-if="user && user.image"
          :src="user.image"
          class="rounded-circle"
          style="height: 60px"
        />
        <img
          v-else
          src="https://redem-resources.s3.eu-central-1.amazonaws.com/default-images/default-avatar.png"
          class="rounded-circle"
          style="height: 60px"
        />
      </div>
      <div class="col-md-10">
        <div style="font-size: medium; font-weight: 600"> {{ this.user.name.first }} {{ this.user.name.last }}</div>
        <div class="mt-1"> {{ this.user.email }} </div>
      </div>
    </div>

    <!-- Text -->
    <div class="row mt-4">
      <div class="col-md-12">
        {{$t("adminComponents.deleteUserOverlay.description")}}
      </div>
    </div>

    <!-- Actions -->
    <div class="row mt-4 mb-3">
      <div class="col-md-12">
        <b-button variant="danger" @click="clickDelete">
          {{$t("adminComponents.deleteUserOverlay.deleteButton")}}
        </b-button>
        <b-button variant="light" @click="clickClose" class="ml-2">
          {{$t("adminComponents.deleteUserOverlay.cancelButton")}}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
